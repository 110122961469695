import React from "react";
import './DashBoardHeading.css'

const DashboardHeading = ({ heading, des }) => {
  return (
    <div className="dashbord-heading-container">
      <h3>{heading}</h3>
      <div>{des}</div>
    </div>
  );
};

export default DashboardHeading;
