import React, { useState } from "react";
import "./DiscussionForm.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../components/CustomTable/CustomTable";

const DiscussionForm = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];

  const columns = [
    { key: "PostID", label: "Post ID" },
    { key: "title", label: "Title" },
    { key: "author", label: "Author" },
    { key: "DateCreated", label: "Date Created" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const data = [
    {
      PostID: "CD001",
      title: "Mastering Job Interviews: Tips from Top Re...",
      author: "Mitchell Johnson",
      DateCreated: "12 Mar 2022",
      status: "Published",
    },
    {
      PostID: "CD002",
      title: "Building a Strong Professional Network Am...",
      author: "Brett Lee",
      DateCreated: "08 Apr 2022",
      status: "Restricted",
    },
    {
      PostID: "CD003",
      title: "5 Ways to Enhance Your LinkedIn Profile to...",
      author: "Ricky Ponting",
      DateCreated: "23 Mar 2022",
      status: "Restricted",
    },
    {
      PostID: "CD004",
      title: "Managing Work-Life Balance in a Busy Worl...",
      author: "Mitchell Johnson",
      DateCreated: "09 May 2023",
      status: "Published",
    },
    {
      PostID: "CD005",
      title: "LinkedIn Profile Mastery",
      author: "Glenn Maxwell",
      DateCreated: "30 Aug 2023",
      status: "Restricted",
    },
  ];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
      // { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Discussion Forum",
    des: "Efficiently manage and moderate discussions to maintain a safe and engaging forum environment.",
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="setting-page-container">
        <div className="sub-setting-page-container blog-post-table-container">
          <div className="dashboard-table-heading">
            <h4>Overview</h4>
          </div>
          <CustomTable
            columns={columns}
            data={data}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default DiscussionForm;
