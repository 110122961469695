import React, { useState } from "react";
import "./ReferrerInformation.css";
import { useNavigate } from "react-router-dom";
import resumeIcon from "../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import profile from "../../../assets/images/people1.jpg"

const ReferrerInformation = () => {
  const navigate = useNavigate();
  return (
    <div className="main-dashboard-container referrer-information-main-container">
      <div className="jra-container">
        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">Amelia Clarke </p>
            </div>
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">Amelia.Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number (Personal)</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
          </div>
        </div>

        {/* company details */}
        <h5 className="jra-heading">Company Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Company Name</p>
              <p className="jra-status-value">XYZ Corp</p>
            </div>
            <div>
              <p className="jra-status-label">Address</p>
              <p className="jra-status-value">Amelia Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Website</p>
              <p className="jra-status-value">XYZcorp.com</p>
            </div>
            <div>
              <p className="jra-status-label">Contact Number</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
          </div>
        </div>

        {/* Current Position*/}
        <h5 className="jra-heading">Current Position</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Referrer's Current Position</p>
              <p className="jra-status-value">Manager</p>
            </div>
          </div>
        </div>

        {/*Identity Verification*/}
        <h5 className="jra-heading">Identity Verification</h5>
        <div className="jra-card">
          <p className="jra-status-label">Submitted Information:</p>
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label-colour">User Image Upload</p>
              <a href="#" className="jra-file-link ">
              <div className="profile-container">
              <img src={profile} className="setting-profile-pic" />
              </div>
              Amelia Clarke
              </a>
            </div>
            <div>
              <p className="jra-status-label-colour">Passport</p>
              <a href="#" className="jra-file-link ">
                <img src={resumeIcon} />
                ABCorpDid.pdf
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReferrerInformation;
