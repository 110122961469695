import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import Logo from "../../../assets/images/konect-logo.svg";
import sidebar from "./config";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    if (window.innerWidth < 1280) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1280 && window.innerWidth > 400) {
        setIsOpen(false);
        document.addEventListener("mousedown", handleClickOutside);
      } else if (window.innerWidth < 400) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log(location.pathname);
  }, [location.pathname]);

  return (
    <div className="sidebar-admin-container">
      <div className="admin-side-bar-user-profile">
        <img
          className="admin-side-bar-user-profile-image"
          src={
            "https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?w=826&t=st=1718746671~exp=1718747271~hmac=c31f22621012b5eb3d26934b901fc0ed0b3393d663f987b82c382172925e4546"
          }
          alt="logo"
        />
      </div>
      <aside className="sidebar-container" ref={containerRef}>
        {isOpen && (
          <div className="sidebar-container-section">
            <div className="sidebar-logo-container">
              <Link to={"/"}>
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <nav>
              <ul>
                {sidebar.map((item, index) => (
                  <Link to={item.path} key={index} onClick={closeSidebar}>
                    <li
                      className={
                        location.pathname === item.path ? "sidebar-active" : ""
                      }
                    >
                      <span>
                        {" "}
                        <img src={item.icon} alt="logo" />
                      </span>
                      {item.title}
                    </li>
                    {item.children &&
                      item.children.map((item, index) => (
                        <Link to={item.path} key={index} onClick={closeSidebar}>
                          <div className="sidebar-child">
                            <li
                              className={
                                location.pathname === item.path
                                  ? "sidebar-active "
                                  : ""
                              }
                            >
                              <span>
                                {" "}
                                <img src={item.icon} alt="logo" />
                              </span>
                              {item.title}
                            </li>
                          </div>
                        </Link>
                      ))}
                  </Link>
                ))}
              </ul>
            </nav>
            <div className="account-container">
              <a>
                <div>
                  <img
                    src={
                      "https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?w=826&t=st=1718746671~exp=1718747271~hmac=c31f22621012b5eb3d26934b901fc0ed0b3393d663f987b82c382172925e4546"
                    }
                    alt="logo"
                  />
                </div>
                <div>
                  <p>Vijay</p>
                  <p>Admin</p>
                </div>
              </a>
            </div>
          </div>
        )}
        <div
          className="menu"
          // onClick={handleMenuClick}
        >
          {!isOpen ? (
            <i className="fa-solid fa-bars" onClick={handleMenuClick}></i>
          ) : (
            <i
              onClick={handleMenuClick}
              className="fa-solid fa-xmark"
              style={{
                color: "white",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            ></i>
          )}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
