import React from "react";
import "./AccountSetting.css";
import profile from "../../../assets/images/adminLayout/Dashboard/profile2.jpg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import wallArt from "../../../assets/images/UserLayout/Icons/wall_art.svg";
import saveIcon from "../../../assets/images/UserLayout/Icons/save.svg";
import keyIcon from "../../../assets/images/UserLayout/Icons/key.svg";

const AccountSetting = ({ privilege }) => {
  return (
    <div className="account-setting-container">
      <h5 className="heading-font-weight">My Profile</h5>
      <h6 className="heading-font-weight">Personal Information</h6>
      <div className="setting-des">
        Keep your personal information accurate to ensure seamless communication
        and account access.
      </div>
      <div className="setting-details-container">
        <div className="profile-container">
          <img src={profile} className="setting-profile-pic" />
          {privilege != "user" ? (
            <>
              <CustomButton
                label="Browse "
                onClick={() => console.log("Button clicked!")}
                type="dashboard-button gray-button"
              />
              <CustomButton
                label="Update"
                onClick={() => console.log("Button clicked!")}
                type="dashboard-button green-button"
              />
            </>
          ) : (
            <>
              <button className="button-common user-dashboard-table-button user-setting-button pay-cancel-button">
                <img
                  src={wallArt}
                  className="user-dashboard-table-button-icon"
                />
                Browse
              </button>

              <button className="button-common user-dashboard-table-button user-setting-button">
                <img
                  src={saveIcon}
                  className="user-dashboard-table-button-icon"
                />
                Update
              </button>
            </>
          )}
        </div>
        <div className="personal-information">
          <div className="contact-form-sub-heading heading-font-weight">
            Name
          </div>
          <input
            className="contact-form-input setting-form-input"
            placeholder="Enter your Name"
          />
          <div className="contact-form-sub-heading heading-font-weight">
            Phone Number
          </div>
          <input
            className="contact-form-input setting-form-input"
            placeholder="Enter your Phone No"
          />
        </div>
        <div className="personal-information">
          <div className="contact-form-sub-heading heading-font-weight">
            E-Mail Address
          </div>
          <input
            className="contact-form-input setting-form-input"
            placeholder="Enter your E-Mail"
          />
          <div className="contact-form-sub-heading heading-font-weight">
            Phone Number (Work)
          </div>
          <input
            className="contact-form-input setting-form-input"
            placeholder="Enter your Phone No"
          />
        </div>
      </div>

      <hr className="account-setting-hr" />
      <h5 className="heading-font-weight">Security</h5>
      <h6 className="heading-font-weight">Password</h6>
      <div className="setting-des">
        Update your password regularly to protect your personal information and
        ensure account safety
      </div>
      <div className="setting-details-container">
        <div className="personal-information">
          <div className="contact-form-sub-heading heading-font-weight">
            Current Password
          </div>
          <input
            className="contact-form-input setting-form-password-input"
            placeholder="Current Password"
          />
          <div className="personal-information-password">
            <div>
              <div className="contact-form-sub-heading heading-font-weight">
                New Password
              </div>
              <input
                className="contact-form-input setting-form-password-input"
                placeholder="New Password"
              />
            </div>

            <div>
              <div className="contact-form-sub-heading heading-font-weight">
                Confirm Password
              </div>
              <input
                className="contact-form-input setting-form-password-input"
                placeholder="Confirm  Password"
              />
            </div>
          </div>
        </div>
      </div>
      {privilege != "user" ? (
        <CustomButton
          label="Change Password"
          onClick={() => console.log("Button clicked!")}
          type="dashboard-button green-button"
        />
      ) : (
        <button className="button-common user-dashboard-table-button">
          <img src={keyIcon} className="user-dashboard-table-button-icon" />
          Change Password
        </button>
      )}
    </div>
  );
};

export default AccountSetting;
