import React from "react";
import "./SuccessServices.css";
import professionalImage from "../../assets/images/professionalImage.svg";
import strongImage from "../../assets/images/strongImage.svg";
import servicesPath from "../../assets/images/service-path-section.svg";

const SuccessServices = () => {
  return (
    <div className="main-success-services-container">
      <div className="success-services-container">
        <div className="success-services-heading">
          Services for{" "}
          <span className="heading-different-color">Your Success</span>
        </div>
        <div className="success-services-heading-des">
          Personalized Support for Every Step of Your Career
        </div>
        <p>
          Welcome to Konect’s "Our Services" page! Here, we provide an overview
          of the comprehensive services we offer to support your career journey,
          whether you're seeking referrals, advice, or assistance with your
          application materials. Our goal is to bridge the gap between job
          seekers and opportunities.
        </p>
        <p>
          At Konect, we are dedicated to empowering job seekers and
          professionals alike. Our platform is designed to create meaningful
          connections and provide valuable resources that support your career
          journey. Whether you're seeking a job referral, offering referrals, or
          looking for career advice, Konect is here to help you succeed.
        </p>
        <div className="success-services-image-container">
          <img src={professionalImage} alt="professionalImage" />
          <img src={strongImage} alt="strongImage" />
        </div>
      </div>
      {/* <div className="servies-path-img-container"> */}
      <img
        className="servies-path-img"
        src={servicesPath}
        alt="services-path"
      />
      {/* </div> */}
    </div>
  );
};

export default SuccessServices;
