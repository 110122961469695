import React from "react";
import "./ContactForm.css";
import mobileIcon from "../../assets/images/callIcon.svg";
import mailIcon from "../../assets/images/mailIcon.svg";
import locationIcon from "../../assets/images/locationIcon.svg";
import facebookIcon from "../../assets/images/facebookIcon.svg";
import linkdinIcon from "../../assets/images/linkdinIcon.svg";
import instaIcon from "../../assets/images/instaIcon.svg";
import youtubeIcon from "../../assets/images/youtubeIcon.svg";
import twiterIcon from "../../assets/images/twiterIcon.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const ContactForm = () => {
  const contactDetails = [
    {
      icon: mobileIcon,
      details: "+1-234-567-890",
    },
    {
      icon: mailIcon,
      details: "support@konect.com",
    },
    {
      icon: locationIcon,
      details: "123 Konect Street, Suite 456, City, Country",
    },
  ];

  const socialMediaIcon = [
    {
      icon: facebookIcon,
    },
    {
      icon: linkdinIcon,
    },
    {
      icon: instaIcon,
    },
    {
      icon: youtubeIcon,
    },
    {
      icon: twiterIcon,
    },
  ];
  return (
    <div className="main-contact-form-container">
      <div className="contact-details-container">
        <div>
          <div className="services-sub-heading">Get in Touch with Us</div>
          <div className="des-paragraph">
            We’re here to assist you! Whether you have inquiries, feedback, or
            need support, our team is ready to respond promptly and effectively.
          </div>
        </div>

        <div>
          <div className="contact-form-sub-heading">Need More Help?</div>
          {contactDetails.map((data) => (
            <div className="contact-icon-details-container">
              <img className="contact-details-icon" src={data.icon}/>
              <div>{data.details}</div>
            </div>
          ))}
        </div>

        <div>
          <div className="contact-form-sub-heading">Follow Us</div>
          <div className="contact-social-media-container">
            {socialMediaIcon.map((data) => (
              <img className="contact-social-media" src={data.icon} />
            ))}
          </div>
        </div>
      </div>
      <div className="contact-form-container">
        <div>
          <div className="services-sub-heading">Contact Form</div>
          <div className="des-paragraph">
            Please fill out the form below, and we’ll respond as soon as
            possible.
          </div>
        </div>

        <div>
          <div className="contact-form-sub-heading">Name</div>
          <input
            className="contact-form-input"
            placeholder="Enter your full name"
          />
          <div className="contact-form-sub-heading">E-Mail Address</div>
          <input
            className="contact-form-input"
            placeholder="Enter your email address"
          />
          <div className="contact-form-sub-heading">Subject</div>
          <input
            className="contact-form-input"
            placeholder="Enter the subject of your inquiry"
          />
          <div className="contact-form-sub-heading">Message</div>
          <textarea
            className="contact-form-textarea"
            placeholder="What are your thoughts?"
            rows="8"
          />
          <div className="contact-form-button-container">
            <CustomButton
              label="Cancel"
              onClick={() => console.log("Button clicked!")}
              type="contact-form-button"
            />
            <CustomButton
              label="Send Message"
              onClick={() => console.log("Button clicked!")}
              type="contact-form-button-purple"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
