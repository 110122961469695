import React, { useState } from "react";
import "./ResumeReview.css";
import TitlePath from "../../../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import chatIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import createIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/createIcon.svg";
import featureSearch from "../../../../../assets/images/adminLayout/Dashboard/Icons/featureSearch.svg";
import checkIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/checkIcon.svg";
import arrowBack from "../../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import saveIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/saveIcon.svg";
import autorenew from "../../../../../assets/images/adminLayout/Dashboard/Icons/autorenew.svg";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

const ResumeReview = () => {
  const navigate = useNavigate();
  const [feedbackAI, setFeedbackAI] = useState("");

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `Service Management`,
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        {/* Header */}
        <div className="jra-header">
          <div className="jra-header-title">
            {/* <span>←</span> */}
            <img src={arrowBack} className="jra-header-title-icon" />
            <h4>
              Job Referrals -{" "}
              <span className="green-heading">Amelia Clarke - JR45845</span>
            </h4>
          </div>
          <div className="jra-header-actions">
            <select className="jra-status-select">
              <option>Mark Status</option>
            </select>
            <button className="jra-chat-button">
              <img src={chatIcon} className="chatIcon" />
              Chat
              <span className="jra-notification-badge">15</span>
            </button>
          </div>
        </div>

        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">Amelia Clarke</p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">2024-07-30</p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">Approved</span>
              </div>
            </div>
          </div>
        </div>

        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">Amelia.Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
          </div>
        </div>

        {/* Industry Selection */}
        <h5 className="jra-heading">Industry Selection</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Industry</p>
              <p className="jra-status-value">Information Technology</p>
            </div>
            <div>
              <p className="jra-status-label">Desired Position</p>
              <p className="jra-status-value">Team Lead</p>
            </div>
            <div>
              <p className="jra-status-label">Desired Company</p>
              <p className="jra-status-value">Quantum Solutions</p>
            </div>
            <div>
              <p className="jra-status-label">Current Job</p>
              <p className="jra-status-value">
                Software Engineer at NextGen Pvt Ltd
              </p>
            </div>
          </div>
        </div>

        {/* Application Details */}
        <h5 className="jra-heading">Application Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Resume</p>
              <a href="#" className="jra-file-link ">
                <img src={resumeIcon} />
                ABCorpDid.pdf
              </a>
            </div>
            <div>
              <p className="jra-status-label">Cover Letter</p>
              <a href="#" className="jra-file-link ">
                <img src={coverLetterIcon} />
                ABCorpDid.doc
              </a>
            </div>
          </div>
        </div>

        {/* AI-Review */}
        <div className="jra-heading-container">
          <h5 className="jra-heading">AI-Review</h5>
          <button className="jra-save-button">
            <img src={autorenew} className="save-icon" />
            Reinitiate AI Review
          </button>
        </div>
        <div className="jra-card">
          <div className="jra-grid-2-col ai-review-container">
            <div>
              <p className="jra-status-label">AI-powered Reviewed CV</p>
              <a href="#" className="jra-file-link ">
                <img src={resumeIcon} />
                AI-powered Reviewed CV.pdf
              </a>
            </div>
            <div>
              <p className="jra-status-label">Feedback</p>
              <textarea
                className="feedback-ai-input-container"
                id="feedbackAI"
                placeholder="Enter your feedback"
                // value={feedbackAI}
                maxLength={300}
                rows={20}
              />
            </div>
          </div>
        </div>

        {/* Consultant-Review*/}
        <div className="jra-heading-container">
          <h5 className="jra-heading">Consultant-Review</h5>
          <button className="jra-save-button">
            <img src={saveIcon} className="save-icon" />
            Save
          </button>
        </div>
        <div className="jra-card">
          <div className="jra-grid-2-col ai-review-container">
          <p className="jra-status-label">CV Consultant Reviewed CV</p>
            <div className="cv-consultant-review-child">
              <a href="#" className="jra-file-link ">
                <img src={resumeIcon} />
                CV Consultant ReviewedCV.pdf
              </a>
              <CustomButton
                label="Upload"
                onClick={() => console.log("Button clicked!")}
                type="contact-form-button gray-square-button"
              />
            </div>
            <div>
              <p className="jra-status-label">Feedback</p>
              <textarea
                className="feedback-ai-input-container"
                id="feedbackAI"
                placeholder="Enter your feedback"
                // value={feedbackAI}
                maxLength={300}
                rows={20}
              />
            </div>
          </div>
        </div>

        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">REF123456789</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">August 19, 2024</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">Credit Card</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">$100</span>
            </div>
          </div>
        </div>

          {/* Status Timeline */}
          <h5 className="jra-heading">Status</h5>
        <div className="jra-card">
          <div className="jra-timeline">
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={createIcon} />
              <div className="jra-timeline-label">Created</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>

            <hr className="jra-timeline-career-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={featureSearch} />
              <div className="jra-timeline-label">Review Started</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>

            <hr className="jra-timeline-career-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={checkIcon} />
              <div className="jra-timeline-label">Completed</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResumeReview;
