import React, { useState } from "react";
import "./Questions.css";

const Questions = () => {
  const general = [
    {
      qus: "What are the benefits of using Konect?",
      ans: `Konect provides several benefits to job seekers:
      
- Access to Internal Referrals: Gain access to internal job referrals, 
which can significantly
increase your chances of securing employment.
- Networking Opportunities: Connect with employees in your desired 
companies or industries.
- Professional Services: Receive resume reviews, interview coaching, 
and career advice from
experienced professionals.
- Level Playing Field: Overcome the barrier of not having personal
 connections within
companies.`,
      num: 1,
    },
    {
      qus: "Is Konect a recruitment agency?",
      ans: `Konect is not a recruitment agency. We don&#39;t employ 
recruiters or directly place candidates in jobs. Instead, 
we provide a platform for job seekers to connect with employees 
who can provide referrals and other career services.`,
      num: 2,
    },
    {
      qus: "Is Konect available globally?",
      ans: `Currently, Konect is only available in Australia. 
We plan to expand to other regions in the future.`,
      num: 3,
    },
    {
      qus: "Does Konect offer customer support?",
      ans: `Absolutely! We&#39;re dedicated to providing excellent customer support.
Our team is available to assist you with any questions or issues you may encounter 
while using Konect.
- Support Channels: You can reach us via email at [Support Email Address] or through the
&quot;Contact Support&quot; form on our website.
- Support Hours: Our support team is available [Days of the week] from [Time] to [Time]
[Time Zone]. We strive to respond to all inquiries within [Number] business hours.`,
      num: 4,
    },
    {
      qus: "Is Konect available on mobile devices?",
      ans: `While we don&#39;t have a dedicated mobile app just yet, our website is fully optimized for mobile
browsing. You can easily access Konect and all its features from your smartphone or tablet.`,
      num: 5,
    },
    {
      qus: "What are Konect'&#39;'s terms of service?",
      ans: `Our terms of service outline the rules and guidelines that govern the use of the Konect platform.
They ensure a safe, fair, and positive experience for all users. You can review the full terms of
service here: [Link to Terms of Service Page].`,
      num: 6,
    },
    {
      qus: "Does Konect verify the information on user profiles?",
      ans: `Yes, we take profile authenticity seriously. We use a combination of automated and manual
checks to verify user information, including employment details and qualifications. This helps
maintain a trustworthy environment for all Konect users. `,
      num: 7,
    },
    {
      qus: "What happens if I violate  Konect's terms of service?",
      ans: `Violating our terms of service can lead to consequences ranging from warnings to account
suspension or termination, depending on the severity of the violation. Here are some examples
of violations:
- Spamming or harassment of other users
- Misrepresenting your identity or qualifications
- Providing false or misleading referrals
- Attempting to circumvent the platform's payment system
You will be notified of any actions taken against your account. We encourage all users to
familiarize themselves with our terms of service to ensure a positive experience on Konect.`,
      num: 8,
    },
    {
      qus: "Is Konect suitable for recent graduates or career changers?",
      ans: `Konect is a valuable resource for anyone looking to advance their career, including recent
graduates and career changers! We offer features specifically designed to support you:
- Guidance for Building Your Profile: Our platform provides tips and resources to help you
craft a compelling profile that highlights your skills and experience, even if you have
limited work history.
- Connecting with Experienced Professionals: Konect gives you the opportunity to network
with experienced professionals who can provide valuable career advice and guidance as
you navigate your job search or career transition.
- Access to a Wide Range of Industries: Explore different career paths and connect with
referrers in various industries to discover new opportunities.`,
      num: 9,
    },
    {
      qus: "Can I use Konect to find a job outside of my current location?",
      ans: `While Konect is currently focused on the Australian job market, you can certainly use the
platform to explore opportunities beyond your current location! Many companies offer remote
positions or are open to relocating employees.
- Connect with Referrers in Other Locations: Use Konect to network with employees in
your desired locations and gain insights into remote work opportunities or company
relocation policies.
- Explore Remote Work Options: Many referrers on Konect can provide information about
remote work options within their companies, even if those companies are not based in
your area.`,
      num: 10,
    },
    {
      qus: "Is Konect a good fit for companies looking to attract top talent?",
      ans: `Konect is an excellent resource for companies looking to enhance their recruitment efforts and
attract top talent. Here's how Konect can benefit your company:
- Tap into Your Employees&#39; Networks: Empower your employees to become brand
ambassadors and refer qualified candidates from their networks.
- Reduce Recruitment Costs: Employee referrals often lead to faster and more cost-
effective hires compared to traditional recruitment methods.
- Improve Candidate Quality: Referred candidates tend to be a better fit for company
culture and have higher retention rates.
- Increase Employee Engagement: Konect provides a platform for employees to actively
participate in the recruitment process, fostering a sense of ownership and engagement.`,
      num: 11,
    },
    {
      qus: "How is Konect different from other job boards or professional networking sites?",
      ans: `Konectdistinguishes itself by focusing specifically on internal referrals. Unlike traditional job boards that
rely on applications alone, Konect provides a direct line to employees within your target
companies. This gives you a significant advantage in the hiring process. While professional
networking sites offer broad connections, Konect facilitates targeted interactions with potential
referrers who can advocate for you within their organizations.`,
      num: 12,
    },
  ];
  const jobSeekers = [
    {
      qus: "How do I find a referrer on Konect?",
      ans: "Finding the right referrer is key to maximizing your chances of success on Konect. Here's how to navigate our platform and connect with referrers who can help you achieve your career goals:\n- Search by Company or Industry: Use our search bar to find referrers who currently work at your target companies or within your desired industry.\n- Filter Your Results: Refine your search using filters like job title, years of experience, areas of expertise, and specific services offered (e.g., resume review, interview coaching).\n- Browse Referrer Profiles: Click on profiles to learn more about each referrer's background, experience, and referral success stories. Pay attention to their ratings and reviews from other job seekers.\n- Explore Recommendations: Konect's algorithm will also suggest potential referrers based on your profile and preferences.",
      num: 1,
    },
    {
      qus: "What services can I request from a referrer?",
      ans: "Konect offers a variety of services to help you enhance your job search and career development:\n- Job Referrals: Request a formal referral for a specific job opening within the referrer's company. This includes the referrer submitting your resume and recommending you to the hiring manager.\n- Resume Reviews: Get expert feedback on your resume to ensure it's clear, concise, and effectively highlights your skills and experience.\n- Interview Coaching: Practice your interview skills with a seasoned professional who can provide valuable tips, feedback, and strategies for success.\n- Career Advice: Receive personalized guidance on your career path, job search strategies, and professional development.",
      num: 2,
    },
    {
      qus: "How much do referrer services cost?",
      ans: "Each referrer sets their own rates for the services they offer. You'll find their pricing clearly displayed on their profiles. Factors that can influence cost include:\n- Type of service: A simple resume review might cost less than a comprehensive interview coaching session.\n- Referrer's experience and expertise: Referrers with more experience or specialized skills may charge higher rates.\n- Demand for the referrer's services: Referrers with high ratings and strong track records may have higher demand and pricing.",
      num: 3,
    },
    {
      qus: "What happens after I request a service?",
      ans: "Once you've found a referrer and requested a service, here's what you can expect:\n- Notification to Referrer: The referrer will receive a notification of your service request.\n- Review and Acceptance: The referrer will review your request and profile. If they are able to fulfill your request, they will accept it.\n- Confirmation and Payment: You will receive a confirmation of the service and be prompted to make a secure payment through the Konect platform.\n- Service Delivery: The referrer will deliver the agreed-upon service, often through direct messaging, video calls, or document sharing within the platform.\n- Ongoing Communication: You can communicate with the referrer throughout the process to ask questions, provide updates, and receive feedback.",
      num: 4,
    },
    {
      qus: "Is it guaranteed that I'll get a job through Konect?",
      ans: "While Konect significantly increases your chances of getting hired by providing access to internal referrals and expert career services, it doesn't guarantee employment. Landing a job depends on several factors, including:\n- Your qualifications and experience: Ensure your skills and experience align with the requirements of the jobs you're targeting.\n- The job market and competition: Be prepared for a competitive job market and tailor your applications accordingly.\n- The hiring company's needs and preferences: Even with a strong referral, the final hiring decision rests with the company.",
      num: 5,
    },
    {
      qus: "What information should I include in my Konect profile?",
      ans: "Your Konect profile is your first impression on potential referrers. Make it count by including the following:\n- Detailed Resume: Upload a comprehensive and up-to-date resume that showcases your skills, experience, and accomplishments.\n- Career Goals and Job Preferences: Clearly state your career aspirations, desired job titles, and preferred industries.\n- Relevant Skills and Experiences: Highlight the skills and experiences that are most relevant to the types of jobs you're seeking.\n- Contact Information: Provide accurate contact information so referrers can easily reach you.\n- Professional Photo: Include a professional headshot to make your profile more personable.\n- Personalized Summary: Write a brief summary that introduces yourself and your career goals.",
      num: 6,
    },
    {
      qus: "How can I improve my chances of getting matched with a referrer?",
      ans: "To increase your chances of connecting with the right referrers:\n- Complete Your Profile: A complete and well-crafted profile demonstrates your seriousness and professionalism.\n- Personalize Your Requests: When requesting a service, send a personalized message to the referrer explaining why you're interested in their help and how their expertise aligns with your needs.\n- Highlight Relevant Skills: Emphasize the skills and experiences that match the referrer's background and the requirements of your target jobs.\n- Be Proactive: Follow up on your requests if you haven't received a response within a reasonable timeframe.\n- Engage with the Konect Community: Participate in forums and groups to expand your network and demonstrate your engagement.",
      num: 7,
    },
    {
      qus: "Can I leave reviews for referrers on Konect?",
      ans: "Yes, we encourage you to leave honest and constructive reviews for referrers after you've used their services. Your feedback helps other job seekers make informed decisions and helps referrers improve their services.",
      num: 8,
    },
    {
      qus: "What if I don't get a response from a referrer?",
      ans: "If you don't hear back from a referrer, here are a few things you can do:\n- Send a Follow-Up Message: Politely follow up on your initial request after a few days.\n- Browse for Other Referrers: Explore other referrer profiles and send requests to those who seem like a good fit.\n- Contact Konect Support: If you've encountered any issues or have concerns about a referrer, our support team is here to assist you.",
      num: 9,
    },
    {
      qus: "How can I network with other job seekers on Konect?",
      ans: "Konect offers several ways to connect with fellow job seekers:\n- Community Forums: Participate in discussions, ask questions, and share insights with other job seekers in our community forums.\n- Industry-Specific Groups: Join groups related to your industry or areas of interest to connect with like-minded professionals.\n- Direct Messaging: Connect with other job seekers directly through our messaging feature.\nBuilding relationships with other job seekers can provide support, motivation, and valuable information as you navigate your career journey.",
      num: 10,
    },
  ];
  
  const referrers = [
    {
        qus: "Who can become a referrer on Konect?",
        ans: "Any employee who wants to help others and earn extra income can become a referrer on Konect! Here's what you need:\n- Current Employment: You must be currently employed to offer referral services. This ensures you have genuine insights into your company and its hiring practices.\n- Verified Employment Details: We'll need to verify your employment details to maintain the integrity of our platform. This typically involves providing some basic information about your role and company.\n- A Desire to Help Others: Being a successful referrer involves a genuine interest in helping job seekers advance their careers.",
        num: 1,
    },
    {
        qus: "How do I set my rates for services?",
        ans: "You have complete control over setting your own rates for the services you offer. Here are some factors to consider:\n- Type of Service: More complex services like interview coaching may warrant higher rates than a resume review.\n- Your Experience and Expertise: If you have extensive experience or specialized skills, you can justify higher rates.\n- Market Research: Check out the rates of other referrers on Konect to get a sense of the going rates for similar services.\n- Value Proposition: Clearly communicate the value you provide to justify your pricing.",
        num: 2,
    },
    {
        qus: "What are my responsibilities as a referrer?",
        ans: "As a Konect referrer, you play a vital role in helping job seekers achieve their career goals. Here are your key responsibilities:\n- Provide Honest and Accurate Referrals: Only refer candidates you genuinely believe are a good fit for the role and the company.\n- Maintain Professionalism: Communicate clearly, respond promptly, and treat all job seekers with respect.\n- Deliver the Agreed-Upon Services: Fulfill your service commitments in a timely and professional manner.\n- Respect Confidentiality: Safeguard the confidentiality of job seekers' information.\n- Adhere to Konect's Terms of Service: Familiarize yourself with our terms and conditions to ensure you're operating within the platform's guidelines.",
        num: 3,
    },
    {
        qus: "How do I get paid for my services?",
        ans: "Konect makes it easy to get paid for your services:\n- Secure Payments: Job seekers make payments through the Konect platform using secure payment methods.\n- Automated Transfers: Once a service is completed, funds are automatically transferred to your designated bank account.\n- Payment Schedule: Payments are typically processed [Specify payment frequency, e.g., weekly, bi-weekly].\n- Keep Your Payment Details Updated: Ensure your payment information is accurate and up-to-date in your profile settings.",
        num: 4,
    },
    {
        qus: "What are the benefits of being a referrer?",
        ans: "Being a referrer on Konect offers numerous benefits:\n- Earn Extra Income: Monetize your expertise and network by providing valuable career services.\n- Make a Difference: Help job seekers advance their careers and achieve their professional goals.\n- Build Your Network: Connect with talented individuals in your field and expand your professional network.\n- Enhance Your Reputation: Establish yourself as a leader and mentor in your industry.\n- Develop Your Skills: Gain experience in coaching, mentoring, and providing professional guidance.",
        num: 5,
    },
    {
        qus: "Can I reject service requests that don't seem like a good fit?",
        ans: "Absolutely! You have the right to decline service requests if:\n- The request doesn't align with your expertise or experience.\n- You don't have the availability to provide quality service.\n- You have concerns about the candidate's qualifications or suitability.\nWhen rejecting a request, communicate your decision clearly and professionally to the job seeker.",
        num: 6,
    },
    {
        qus: "Are there any limitations on the number of referrals I can provide?",
        ans: "While there are no strict limits on the number of referrals you can provide, we encourage you to manage your workload responsibly. Overcommitting can lead to:\n- Reduced service quality: You may not be able to dedicate sufficient time and attention to each job seeker.\n- Negative reviews: Dissatisfied job seekers may leave negative reviews if they feel they haven't received adequate support.\n- Damage to your reputation: Overcommitting can negatively impact your reputation as a reliable referrer.",
        num: 7,
    },
    {
        qus: "How can I build a strong reputation as a referrer on Konect?",
        ans: "Building a strong reputation on Konect is crucial for attracting job seekers and maximizing your earnings. Here's how:\n- Deliver Excellent Service: Provide high-quality services that exceed expectations.\n- Maintain Professionalism: Communicate effectively, respond promptly, and be respectful in all interactions.\n- Gather Positive Reviews: Encourage satisfied job seekers to leave positive reviews about their experience with you.\n- Showcase Your Expertise: Highlight your skills, experience, and accomplishments in your profile.\n- Be Responsive: Respond to service requests promptly and maintain active communication with job seekers.",
        num: 8,
    },
    {
        qus: "What are some ethical considerations when providing referrals?",
        ans: "Maintaining ethical standards is essential for building trust and credibility as a referrer. Here are some key principles to follow:\n- Honesty and Integrity: Provide honest and unbiased assessments of candidates' qualifications.\n- Avoid Conflicts of Interest: Disclose any potential conflicts of interest that may influence your referral decisions.\n- Confidentiality: Respect the confidentiality of job seekers' information.\n- Transparency: Be transparent with job seekers about your referral process and any limitations.",
        num: 9,
    },
];

  const payments = [
    {
        qus: "How do I pay for services on Konect?",
        ans: "Konect offers a variety of secure and convenient payment methods to purchase services from referrers:\n- Credit Cards: We accept all major credit cards, including Visa, Mastercard, American Express, and Discover.\n- Digital Wallets: Pay seamlessly using popular digital wallets like Apple Pay, Google Pay, and PayPal.\n- [Other Payment Methods (if applicable)]: [List any other payment options you offer, e.g., bank transfers, debit cards].\nOur payment process is encrypted and PCI DSS compliant to ensure the security of your financial information.",
        num: 1,
    },
    {
        qus: "Are there any transaction fees?",
        ans: "Konect charges a small transaction fee to cover the costs of processing payments and maintaining the platform's security. The exact fee amount will be clearly displayed during the checkout process before you confirm your payment.",
        num: 2,
    },
    {
        qus: "What happens if a service is not completed?",
        ans: "If a referrer fails to deliver the agreed-upon service, you may be eligible for a full or partial refund. Here's what you can do:\n- Contact the Referrer: First, try to communicate with the referrer directly to resolve the issue.\n- Contact Konect Support: If you're unable to resolve the issue with the referrer, contact our customer support team. We'll investigate the situation and assist you in getting a refund or finding an alternative referrer to complete the service.",
        num: 3,
    },
    {
        qus: "Can I cancel a service request?",
        ans: "Yes, you can cancel a service request, but our cancellation policy may vary depending on the timing and circumstances of the cancellation:\n- Before Payment: If you cancel before making a payment, there is no penalty.\n- After Payment: If you cancel after making a payment, you may be eligible for a partial or full refund, depending on whether the referrer has started working on your request.\n- Referrer Cancellation: If a referrer cancels a service request, you will receive a full refund.\nPlease refer to our detailed cancellation policy [link to cancellation policy] for more information.",
        num: 4,
    },
    {
        qus: "How do I view my transaction history?",
        ans: "You can easily access your transaction history on Konect:\n1. Log in to your account.\n2. Go to your 'Account Settings.'\n3. Click on the 'Transaction History' tab.\nYou'll find a record of all your payments made and received on the platform, including dates, amounts, and service details.",
        num: 5,
    },
    {
        qus: "Are there any additional fees besides the transaction fee?",
        ans: "The only fees you'll encounter on Konect are:\n- Transaction Fees: As mentioned earlier, a small transaction fee applies to each payment processed on the platform.\n- Referrer Service Fees: Each referrer sets their own rates for the services they offer. These rates are clearly displayed on their profiles and during the checkout process.\nThere are no hidden fees or subscription charges on Konect.",
        num: 6,
    },
    {
        qus: "How long does it take to receive a refund if a service request is cancelled?",
        ans: "Refunds are typically processed within [Number] business days of the cancellation request. The exact time may vary depending on your payment method and your bank's processing times. If you haven't received your refund within the expected timeframe, please contact our customer support team for assistance.",
        num: 7,
    },
    {
        qus: "Does Konect offer any payment protection guarantees?",
        ans: "Yes, Konect prioritizes the security of your transactions and offers payment protection measures to safeguard your financial information and ensure you receive the services you paid for. These measures include:\n- Secure Payment Gateway: We use a secure and encrypted payment gateway to process all transactions.\n- Fraud Detection: Our system monitors for suspicious activity to prevent fraud and unauthorized transactions.\n- Refund Policy: We offer a clear and fair refund policy to protect you in case a service is not completed.",
        num: 8,
    },
    {
        qus: "What happens if there is a technical issue during a transaction on Konect?",
        ans: "If you encounter any technical issues during a transaction, such as payment errors or website glitches, please contact our customer support team immediately. We'll work to resolve the issue promptly and ensure your transaction is completed successfully.",
        num: 9,
    },
    {
        qus: "Does Konect offer any chargeback protection for job seekers?",
        ans: "Yes, we understand that things can go wrong, and we want to protect our job seekers from unauthorized or fraudulent transactions. If you have a valid reason to dispute a charge on your account, you can initiate a chargeback request through your bank or credit card provider. Our customer support team can assist you with this process and provide any necessary documentation.",
        num: 10,
    },
];

  const privacyAndSecurity = [
    {
        qus: "How does Konect protect my privacy?",
        ans: "Konect takes your privacy seriously. We implement a variety of security measures to protect your personal information and ensure a safe and secure platform experience:\n- Data Encryption: We use industry-standard encryption protocols (like SSL/TLS) to protect your data during transmission and while it's stored on our servers.\n- Access Controls: We restrict access to your personal information to authorized personnel who require it to perform their job duties.\n- Regular Security Assessments: We conduct regular security assessments and vulnerability scans to identify and address potential risks.\n- Secure Data Centers: Our servers are located in secure data centers with physical and environmental security measures in place.\n- Data Privacy Policy: We adhere to strict data privacy principles and comply with relevant data protection regulations.",
        num: 1,
    },
    {
        qus: "Can I keep my job title and company anonymous when seeking a referral?",
        ans: "Yes, you have control over what information is visible on your profile. You can choose to keep your current job title and company anonymous if you prefer to maintain confidentiality during your job search. Simply adjust your privacy settings in your profile to control what information is displayed to others.",
        num: 2,
    },
    {
        qus: "Who can see my profile on Konect?",
        ans: "You can customize your profile visibility to control who can see your information:\n- Public: Your profile is visible to anyone on Konect and potentially through search engine results.\n- Konect Users Only: Your profile is only visible to other registered users on Konect.\n- Connections Only: Your profile is only visible to users you have connected with on the platform.\n- Private: Your profile is only visible to you and Konect administrators.\nYou can adjust your visibility settings at any time to suit your preferences.",
        num: 3,
    },
    {
        qus: "How do I report a suspicious user or activity?",
        ans: "If you encounter any suspicious users or activity on Konect, please report it immediately so we can investigate and take appropriate action. Here's how:\n- Report Feature: Use the 'Report User' or 'Report Activity' feature available on user profiles or within specific interactions (e.g., messages).\n- Contact Support: Contact our customer support team directly through email or our online contact form.\nWhen reporting, please provide as much detail as possible, including usernames, specific interactions, and any evidence you may have.",
        num: 4,
    },
    {
        qus: "Can I choose what information I share on my Konect profile?",
        ans: "Yes, you have complete control over the information you share on your Konect profile. You can choose to include or exclude details such as:\n- Work Experience: Specific job titles, companies, dates of employment, and descriptions of responsibilities.\n- Education: Degrees, institutions, and graduation dates.\n- Skills: List of your professional skills and areas of expertise.\n- Contact Information: Email address, phone number, and social media links (optional).\n- Personal Information: Location, interests, and other personal details (optional).\nReview your profile settings to customize what information is displayed.",
        num: 5,
    },
    {
        qus: "Does Konect share my personal information with third parties?",
        ans: "Konect respects your privacy and does not share your personal information with third parties without your explicit consent, except in the following limited circumstances:\n- Service Providers: We may share information with trusted third-party service providers who assist us with essential functions like payment processing, data hosting, or customer support. These providers are contractually obligated to protect your information.\n- Legal Requirements: We may disclose your information if required by law, court order, or government regulation.",
        num: 6,
    },
    {
        qus: "What happens to my data if I deactivate my Konect account?",
        ans: "If you decide to deactivate your Konect account:\n- Data Retention: Your data will be securely stored in case you decide to reactivate your account in the future.\n- Data Deletion: You can request permanent deletion of your data by contacting our customer support team.\n- Profile Visibility: Your profile will no longer be visible to other users on the platform.",
        num: 7,
    },
    {
        qus: "Does Konect offer two-factor authentication (2FA) for added security?",
        ans: "Yes, we offer two-factor authentication (2FA) as an optional security measure to further protect your account from unauthorized access. When 2FA is enabled, you'll need to provide a unique verification code in addition to your password when logging in. You can enable 2FA in your account settings.",
        num: 8,
    },
    {
        qus: "How does Konect use cookies?",
        ans: "Konect uses cookies to enhance your browsing experience and provide personalized features. Cookies are small text files that are stored on your device when you visit our website. They help us remember your preferences, track your activity, and provide relevant content. You can manage your cookie preferences in your browser settings.",
        num: 9,
    },
];


  const [selectedRadio, setSelectedRadio] = useState("general");
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [questionData, setQuestionData] = useState(general);
  const [colourChange, setColourChange] = useState(true);

  const handleChange = (event) => {
    setSelectedRadio(event.target.value);
    setActiveQuestion(null);
    if (event.target.value === "general") {
      setQuestionData(general);
    }
    if (event.target.value === "jobSeekers") {
      setQuestionData(jobSeekers);
    }
    if (event.target.value === "referrers") {
      setQuestionData(referrers);
    }
    if (event.target.value === "payments") {
      setQuestionData(payments);
    }
    if (event.target.value === "privacyAndSecurity") {
      setQuestionData(privacyAndSecurity);
    }
  };

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
    console.log(index);
  };

  return (
    <div className="main-questions-container">
      <div className="questions-heading">
        <h1 className="services-heading">
          Frequently Asked{" "}
          <span className="heading-different-color">Questions</span>
        </h1>
        <div className="services-heading-des">
          Find quick answers to common questions about Konect and how our
          services can help boost your career
        </div>
      </div>

      <div className="questions-details-container">
        <div className="questions-filter-container">
          <div>Filter by category</div>
          <div>
            Find Answers to Common Questions. Quickly find the answers you need
            by browsing our most frequently asked questions, organized into
            helpful categories.
          </div>
          <div className="filter-radio-button-container">
            <label
              style={{
                color: selectedRadio === "general" ? "#1AD079" : "inherit",
              }}
            >
              <input
                type="radio"
                name="radioGroup"
                value="general"
                checked={selectedRadio === "general"}
                onChange={handleChange}
              />
              General
            </label>
            <label
              style={{
                color: selectedRadio === "jobSeekers" ? "#1AD079" : "inherit",
              }}
            >
              <input
                type="radio"
                name="radioGroup"
                value="jobSeekers"
                checked={selectedRadio === "jobSeekers"}
                onChange={handleChange}
              />
              Job Seekers
            </label>
            <label
              style={{
                color: selectedRadio === "referrers" ? "#1AD079" : "inherit",
              }}
            >
              <input
                type="radio"
                name="radioGroup"
                value="referrers"
                checked={selectedRadio === "referrers"}
                onChange={handleChange}
              />
              Referrers
            </label>
            <label
              style={{ color: selectedRadio === "payments" ? "#1AD079" : "" }}
            >
              <input
                type="radio"
                name="radioGroup"
                value="payments"
                checked={selectedRadio === "payments"}
                onChange={handleChange}
              />
              Payments
            </label>
            <label
              style={{
                color: selectedRadio === "privacyAndSecurity" ? "#1AD079" : "",
              }}
            >
              <input
                type="radio"
                name="radioGroup"
                value="privacyAndSecurity"
                checked={selectedRadio === "privacyAndSecurity"}
                onChange={handleChange}
              />
              Privacy and Security
            </label>
          </div>
        </div>
        <div className="questions-des-container">
          {questionData.map((data) => (
            // <div className="questions-container">
            <div
                key={data.num}
                className="questions-container"
                style={{
                  color: activeQuestion === data.num ? "#1AD079" : "inherit",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(data.num)}
              >
              <div
                className="question-header"
                // onClick={() => toggleQuestion(data.num)}
              >
                <div>{data.qus}</div>
                <div>
                  {activeQuestion === data.num ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </div>
              </div>
              {activeQuestion === data.num && (
                <div className="question-answer">
                {data.ans}
                </div>
              )}
              <hr className="questions-hr" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
