// ImageUploadGallery.jsx
import React, { useState } from 'react';
import './ImageUploadGallery.css';
import uploadImg from "../../assets/images/adminLayout/Dashboard/Icons/upload-icon.svg"

const ImageUploadGallery = () => {
  const [images, setImages] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const removeImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <div key={index} className="image-container">
          <img
            src={image.preview}
            alt={`Upload ${index + 1}`}
            className="uploaded-image"
          />
          <button
            onClick={() => removeImage(index)}
            className="remove-button"
            aria-label="Remove image"
          >
            ×
          </button>
        </div>
      ))}
      <label className="upload-label">
        {/* <span className="upload-icon">+</span> */}
        <img src={uploadImg} className="upload-icon" />
        <span>Upload</span>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="file-input"
          accept="image/*"
        />
      </label>
    </div>
  );
};

export default ImageUploadGallery;