import React, { useState } from "react";
import "./ChatManager.css";
import TitlePath from "../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import people1 from "../../../assets/images/people1.jpg";
import people2 from "../../../assets/images/people2.jpg";
import people3 from "../../../assets/images/people3.jpg";
import requestIcon from "../../../assets/images/adminLayout/Dashboard/Icons/requestIcon.svg";
import ChatManagerSideBar from "../../../containers/AdminLayout/ChatManager/ChatManagerSideBar/ChatManagerSideBar";
import ChatContainer from "../../../containers/AdminLayout/ChatManager/ChatContainer/ChatContainer";

const ChatManager = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Job Referrals");

  const tabs = [
    { name: "Job Referrals" },
    { name: "Career Advice" },
    { name: "Resume Review" },
  ];

  const jobListings = [
    {
      id: 1,
      title: "Software Engineer",
      company: "ABC (PVT) LTD - Software...",
      time: "03:40am",
      badge: 15,
      img1: people1,
      img2: people2,
    },
    {
      id: 1,
      title: "Software Engineer",
      company: "ABC (PVT) LTD - Software...",
      time: "03:40am",
      badge: 15,
      img1: people1,
      img2: people2,
    },
    {
      id: 1,
      title: "Software Engineer",
      company: "ABC (PVT) LTD - Software...",
      time: "03:40am",
      badge: 15,
      img1: people1,
      img2: people2,
    },
    {
      id: 1,
      title: "Software Engineer",
      company: "ABC (PVT) LTD - Software...",
      time: "03:40am",
      badge: 15,
      img1: people1,
      img2: people2,
    },
    {
      id: 1,
      title: "Software Engineer",
      company: "ABC (PVT) LTD - Software...",
      time: "Yesterday",
      badge: 15,
      img1: people1,
      img2: people2,
    },
  ];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `Service Management`,
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="chat-container">
        <div className="jra-header">
          <div className="jra-header-title">
            <img src={arrowBack} className="jra-header-title-icon" />
            <h4>Chat</h4>
          </div>
        </div>
      </div>
      <div className="jri-container">
        {/* <div className="jri-navbar">
        <div className="jri-tabs">
          <button className="jri-tab jri-tab-active">Job Referrals</button>
          <button className="jri-tab">Career Ad...</button>
          <button className="jri-tab">Resume R...</button>
        </div>
      </div> */}

        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`chat-tab ${activeTab === tab.name ? "active" : ""}`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>

        <div className="jri-content">
          <div className="jri-sidebar">
            <div className="jri-search-container">
              <input
                type="text"
                placeholder="Search"
                className="jri-search-input"
              />
            </div>

          <ChatManagerSideBar/>
          </div>

          <div className="jri-chat-container">
            <div className="jri-chat-header">
              <div className="jri-chat-users">
                <div className="jri-user">
                  <img
                    src={people1}
                    alt="User avatar"
                    className="jri-user-avatar"
                  />
                  <div className="jri-user-info">
                    <span className="jri-user-name">Amelia Clarke</span>
                    <span className="jri-user-role">(User)</span>
                  </div>
                </div>
                <div className="jri-user">
                  <img
                    src={people3}
                    alt="Referrer avatar"
                    className="jri-user-avatar"
                  />
                  <div className="jri-user-info">
                    <span className="jri-user-name">John Philip</span>
                    <span className="jri-user-role">(Referrer)</span>
                  </div>
                </div>
              </div>
              <div className="jri-chat-actions">
                <span className="jri-status-badge">Pending</span>
                <button className="jri-view-btn">
                  <img src={requestIcon} />
                  View Request
                </button>
              </div>
            </div>

            {/* <div className="jri-chat-messages">
              <div className="jri-message jri-message-user">
                <img src={people1} alt="User" className="jri-message-avatar" />
                <div className="jri-message-content">
                  <p>Hey Nice to meet you</p>
                </div>
              </div>

              <div className="jri-message jri-message-referrer">
                <div className="jri-message-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipis cing elit,
                    sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit
                    amet, consectetur adipis cing elit, sed do eiusmod tempor
                    incididunt ut.u
                  </p>
                </div>
                <img
                  src={people3}
                  alt="Referrer"
                  className="jri-message-avatar"
                />
              </div>
            </div> */}

            <ChatContainer/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatManager;
