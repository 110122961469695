import React, { useState } from "react";
import "./DiscussionFormSidebar.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import user from "../../../assets/images/people5.jpg";
import user1 from "../../../assets/images/people1.jpg";
import user2 from "../../../assets/images/people5.jpg";
import user3 from "../../../assets/images/people3.jpg";

const DiscussionFormSidebar = () => {
  const UpVotesData = [
    { name: "Melissa Baker", img: user1 },
    { name: "Melissa Baker", img: user3 },
    { name: "Leroy Nelson", img: user1 },
    { name: "Roy Brown", img: user3 },
    { name: "Howard Robinson", img: user1 },
  ];

  const DownVotesData = [
    { name: "Rebecca Lee", img: user2 },
    { name: "Melissa Baker", img: user2 },
    { name: "Leroy Nelson", img: user2 },
    { name: "Roy Brown", img: user2 },
    { name: "Howard Robinson", img: user2 },
  ];

  const [showUpvotes, setShowUpvotes] = useState(true);
  const [showDownvotes, setShowDownvotes] = useState(true);

  const toggleUpvotes = () => setShowUpvotes(!showUpvotes);
  const toggleDownvotes = () => setShowDownvotes(!showDownvotes);

  return (
    <div className="post-info-card">
      <div className="card-header">
        <h5>Posted By</h5>
        <CustomButton
          label="View"
          onClick={() => console.log("Button clicked!")}
          type="contact-form-button-green discussion-comment-button"
        />
      </div>
      <div className="user-info">
        <div className="postby-user-img-container">
          <img className="postby-user-img" src={user} alt="host" />
        </div>
        <div>
          <h6>Alex Carl</h6>
          <p className="post-user-email">Alexcarl07@gmail.com</p>
        </div>
      </div>
      <div className="post-details">
        <div>
          <p className="postby-heading">Post ID</p>
          <p className="postby-des">PID001</p>
        </div>
        <div>
          <p className="postby-heading">Published Date</p>
          <p className="postby-des">12 Mar 2022</p>
        </div>
      </div>
      <p className="postby-heading">Status</p>
      <div className="status">
        <span>Published</span>
      </div>
      <div className="votes">
        <div>
          <p className="postby-heading">27 Up-votes</p>
          <button onClick={toggleUpvotes} className="vote-toggle">
            <span className={`arrow ${showUpvotes ? "up" : "down"}`}></span>
          </button>
        </div>
        {/* {showUpvotes && (
          <ul className="vote-list">
            {[
              "Melissa Baker",
              "Leroy Nelson",
              "Roy Brown",
              "Howard Robinson",
            ].map((name, index) => (
              <li key={index}>
                <img src={`placeholder-avatar-${index + 1}.jpg`} alt={name} />
                <span className="postby-user-name">
                  Up-votes by <span className="blue-text">{name}</span>
                </span>
              </li>
            ))}
          </ul>
        )} */}

        {showUpvotes && (
          <div className="vote-list">
            {UpVotesData.map((data) => (
              <div>
                <img src={data.img} className="postby-user-img"/>
                <span className="postby-user-name">
                  Up-votes by <span className="blue-text">{data.name}</span>
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="votes">
        <div>
          <p className="postby-heading">17 Down-votes</p>
          <button onClick={toggleDownvotes} className="vote-toggle">
            <span className={`arrow ${showDownvotes ? "up" : "down"}`}></span>
          </button>
        </div>
        {showDownvotes && (
         <div className="vote-list">
         {DownVotesData.map((data) => (
           <div>
             <img src={data.img} className="postby-user-img" />
             <span className="postby-user-name">
             Down-vote by <span className="blue-text">{data.name}</span>
             </span>
           </div>
         ))}
       </div>
        )}
      </div>
    </div>
  );
};

export default DiscussionFormSidebar;
