import React from "react";
import "./JoinCommunity.css";
import leftImage from "../../assets/images/KonectMazeLeft.svg";
import rightImage from "../../assets/images/KonectMazeRight.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const JoinCommunity = () => {
  return (
    <div className="main-joincommunity-container">
      <div>
        {/* <div>
          <img
            className="community-left-image"
            src={rightImage}
            alt="community-image"
          />
        </div> */}
        <div className="JoinCommunity-details-container">
          <h2 className="joincommunity-heading">
            Stay Updated with{" "}
            <span className="heading-different-color">Konect</span>
          </h2>
          <p>
            Subscribe to Our Newsletter for the Latest Updates and Opportunities
          </p>
          <p>
            Join our community and be the first to know about new features,
            career tips, success stories, and exclusive opportunities. Our
            newsletter delivers valuable insights directly to your inbox.
          </p>
          {/* <div className="input-button-container"> */}
          <div className="input-container">
            <input className="email-input" placeholder="Type your email here" />
            <button
              className="submit community-button"
              onClick={() => console.log("Button clicked!")}
            >
              Subscribe
            </button>
          </div>

          {/* </div> */}
        </div>
        {/* <div>
          <img
            className="community-right-image"
            src={leftImage}
            alt="community-image"
          />
        </div> */}
      </div>
    </div>
  );
};

export default JoinCommunity;
