import {
  CREATE_JOBSEEKER_SUCCESS,
  CREATE_JOBSEEKER_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  GET_JOB_REFERRAL_BY_ID,
  GET_JOB_REFERRALS,
  UPDATE_JOB_SEEKER_FEEDBACK,
  CREATE_JOB_REFERRAL_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SEND_OTP_SUCCESS,
  FORGOT_PASSWORD_SEND_OTP_FAILURE,
  FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
} from "../actions/jobSeekerAction";

const initialState = {
  jobSeeker: [],
  selectedJobReferral:null,
  dashboard:null,
  isAuthenticated: false,
  error: null,
  loading: false,
  otpSent: false,
  passwordReset: false,
};

// Authentication Reducer
const jobSeekerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_JOBSEEKER_SUCCESS:
      return {
        ...state,
        jobSeeker: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case CREATE_JOBSEEKER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

      case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        error: null,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case GET_JOB_REFERRAL_BY_ID:
      return {
        ...state,
        selectedJobReferral: action.payload,
      };

      case GET_JOB_REFERRALS:
        return {
            ...state,
            jobSeeker: action.payload,
        };

    case UPDATE_JOB_SEEKER_FEEDBACK:
      return {
        ...state,
        jobSeeker: state.jobSeeker.data.map((jobSeeker) =>
          jobSeeker._id === action.payload._id ? action.payload : jobSeeker
        ),
      };
      
    case CREATE_JOB_REFERRAL_REQUEST:
      return {
          ...state,
          jobSeeker: [...state.jobSeeker, action.payload],
      };
      
    case LOGIN_SUCCESS:
      return {
        ...state,
        jobSeeker: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case RESEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case FORGOT_PASSWORD_SEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default jobSeekerReducer;
