import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./SuccessStories.css";
import memberImage1 from "../../assets/images/memberImage1.jpg";
import memberImage2 from "../../assets/images/memberImage2.jpg";
import memberImage3 from "../../assets/images/memberImage3.jpg";

import ArrowPrevious from "../../assets/images/arrow-previous.svg";
import ArrowNext from "../../assets/images/arrow-next.svg";

const SuccessStories = () => {
  const successStoriesData = [
    {
      name: "Kai B.",
      designation: "Software Engineer",
      des: "Konect ‘konected’ me with my dream job! I wasnt getting any traction with my applications, then I found a software engineer at my target company on Konect. They reviewed my resume, offered interview practice, and even referred me for an opening! Now I am coding away and loving it!",
      memberImg: memberImage1,
    },
    {
      name: "John",
      designation: "QA",
      des: "Konect ‘konected’ me with my dream job! I wasnt getting any traction with my applications, then I found a software engineer at my target company on Konect. They reviewed my resume, offered interview practice, and even referred me for an opening! Now I am coding away and loving it!",
      memberImg: memberImage2,
    },
    {
      name: "Peter",
      designation: "Project Manager",
      des: "Konect ‘konected’ me with my dream job! I wasnt getting any traction with my applications, then I found a software engineer at my target company on Konect. They reviewed my resume, offered interview practice, and even referred me for an opening! Now I am coding away and loving it!",
      memberImg: memberImage3,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === successStoriesData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? successStoriesData.length - 1 : prevIndex - 1
    );
  };

  const currentStory = successStoriesData[currentIndex];
  const nextProfile =
    successStoriesData[(currentIndex + 1) % successStoriesData.length];
  const thirdProfile =
    successStoriesData[(currentIndex + 2) % successStoriesData.length];

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
  });

  return (
    <div className="main-success-stories-container">
      <h1 className="services-heading">
        Our Success <span className="heading-different-color">Stories</span>
      </h1>
      <div className="services-heading-des">
        Hear from members of the Konect community, both job seekers and
        referrers, on how they have benefited from our range of services.
      </div>
      <div {...handlers} className="main-our-success-stories-container">
        <div>
          <div>
            <div className="success-stories-container">
              <div className="member-privious-button-container">
                {/* <i
                  className="fa-solid fa-less-than member-privious-next-button"
                  onClick={handlePrevious}
                ></i> */}

                <img
                      src={ArrowPrevious}
                      alt="Previous"
                      className="member-privious-next-button"
                      onClick={handlePrevious}
                     
                    />
              </div>
              <div>
                <div className="stories-description-container">
                  <div>{currentStory.name}</div>
                  <div>{currentStory.designation}</div>
                  <div>{currentStory.des}</div>
                </div>
                <div className="profile-images-container">
                  <div>
                    <div>
                      <div className="first-member-image-container">
                        <img
                          className="current-member-image"
                          src={currentStory.memberImg}
                          alt="member-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="members-image-container">
                        <img
                          className="other-member-image"
                          src={nextProfile.memberImg}
                          alt="member-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="members-image-container">
                        <img
                          className="other-member-image"
                          src={thirdProfile.memberImg}
                          alt="member-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="member-next-button-container">
                {/* <i
                  className="fa-solid fa-greater-than member-privious-next-button"
                  onClick={handleNext}
                ></i> */}

                    <img
                      src={ArrowNext}
                      alt="Next"
                      className="member-privious-next-button"
                      onClick={handleNext}
                     
                    />
              </div>
            </div>
          </div>
          {/* Circles for indicating current slide */}
          <div className="circles-container">
            {successStoriesData.map((_, index) => (
              <div
                key={index}
                className={`circle ${
                  index === currentIndex ? "active-circle" : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStories;
