import React from "react";
import LearningHubArticleView from "../../containers/LearningHub/LearningHubArticleView";

const ArticlePage = () => {
  return (
    <div className="main-home-page-container">
      <div className="main-home-container">
        <LearningHubArticleView />
      </div>
    </div>
  );
};

export default ArticlePage;
