import React from "react";
import "./Home.css";
import Header from "../containers/Home/Header";
import WhoCanUse from "../containers/Home/WhoCanUse";
import Services from "../containers/Home/Services";
import JoinCommunity from "../containers/Home/JoinCommunity";
import SuccessStories from "../containers/Home/SuccessStories";
import Questions from "../containers/Home/Questions";

const Home = () => {
  return (
    <>
      <Header />
      <div className="main-home-container">
        <WhoCanUse />
        <Services />
        <SuccessStories />
        <Questions />
        <JoinCommunity />
      </div>
    </>
  );
};

export default Home;
