import React from "react";
import "./LearningHubArticleView.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import learninghubImg1 from "../../assets/images/learninghubImg1.svg";
import { useNavigate } from "react-router-dom";

const LearningHubArticleView = () => {
  const navigate = useNavigate();
  const articleData = {
    img: learninghubImg1,
    name: "Mitchell Johnson",
    date: "27 April 2024",
    heading: "Mastering Job Interviews: Tips from Top Recruiters",
    des: "Landing a job interview is a significant step in your career journey, but mastering the interview itself is where the real challenge lies. To help you succeed, we’ve gathered insights from top recruiters. Before you step into the interview room, make sure you know the company inside out. Top recruiters emphasize the importance of understanding the company’s mission, values, products, and recent achievements. This knowledge demonstrates your genuine interest and helps you tailor your responses to align with the company’s goals.",
  };

  const tags = [
    {
      tag: "Job Interview",
    },
    {
      tag: "Guidance",
    },
    {
      tag: "Talent Acquisition ",
    },
  ];
  return (
    <div className="main-articleview-container">
      <div className="articleview-back-button-container">
        <CustomButton
          label="← Back to Learning Hub"
          onClick={() => navigate("/learningHub")}
          type="backto-button"
        />
      </div>
      <div className="articleview-img-container">
        <img
          className="articleview-image"
          src={articleData.img}
          alt="article-image"
        />
      </div>
      <div className="articleview-des-container">
        <div className="learninghub-name-date-container">
          <div>{articleData.name}</div>
          <div>{articleData.date}</div>
        </div>
        <h3 className="articleview-heading">{articleData.heading}</h3>
        <p className="articleview-des">{articleData.des}</p>

        <div className="tag-container">
          <p className="articleview-des">Tags</p>
          {tags.map((data) => (
            <CustomButton
              label={data.tag}
              onClick={() => console.log("Button clicked!")}
              type="tag-button"
            />
          ))}
        </div>
        <div className="pre-next-article-container">
          <div className="pre-article-container">
            <div>Previous Post</div>
            <div>
              <i class="fa-solid fa-arrow-left"></i>
              <div>
                Creating Impactful Resumes and Cover Letters to Stand Out Higher
                in the Industry World
              </div>
            </div>
          </div>
          <div className="pre-article-container">
            <div>Next Post</div>
            <div>
              <div>
                Building a Strong Professional Network Among the Fostering World
              </div>
              <i class="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningHubArticleView;
