import React from 'react'
import TitlePath from '../../../components/TitlePath/TitlePath'
import { useNavigate } from 'react-router-dom';

export default function Index() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(`/admin_dashboard`);
      };
    const titlePath = {
        exactPath: ` Dashboard`,
        paths: [
     
    
        ]
      };
  return (
    <div>
         <TitlePath
              exactPath={titlePath.exactPath}
              paths={titlePath.paths}
            />
    </div>
  )
}
