import React from "react";
import "./HelpCenter.css";
import searchIcon from "../../assets/images/searchIcon.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import questionIcon from "../../assets/images/questionIcon.svg";
import contactUsIcon from "../../assets/images/contactUsIcon.svg";
import feedbackIcon from "../../assets/images/feedbackIcon.svg";
import { useNavigate } from "react-router-dom";

const HelpCenter = () => {
  const navigate = useNavigate();

  const handleQuestion = () => {
    navigate("/questions");
  };

  const handleContactUs = () => {
    navigate("/contactForm");
  };

  const handlefeedback = () => {
    navigate("/provideFeedback");
  };
  const helpData = [
    {
      icon: questionIcon,
      heading: "Frequently Asked Questions",
      des1: "Find quick answers to common questions about our services and platform.",
      des2: "Browse through our FAQs to get answers to the most common questions. If you can't find what you're looking for, feel free to reach out to us.",
      onclick: handleQuestion,
    },
    {
      icon: contactUsIcon,
      heading: "Contact Us",
      des1: "Get in touch with our support team for personalized assistance.",
      des2: "If you have any inquiries, feedback, or support requests, please fill out our contact form. We're here to help!",
      onclick: handleContactUs,
    },
    {
      icon: feedbackIcon,
      heading: "Provide Feedback",
      des1: "Your feedback helps us improve our services and better serve you.",
      des2: "We value your insights and suggestions. Please provide your feedback to help us enhance your experience with Konect.",
      onclick: handlefeedback,
    },
  ];

  return (
    <div className="help-center-container">
      <div className="help-center-search-container">
        <div>How Can We Assist You?</div>
        <div>
          <img
            className="search-icon-help-center"
            src={searchIcon}
            alt="search icon"
          />
          <input
            className="help-center-search-input"
            placeholder="What do you want to ask?"
          />
        </div>
      </div>

      <div className="services-container">
        {helpData.map((data) => (
          <div
            className="sub-services-container help-center-card-container"
            onClick={data.onclick}
          >
            <div>
              <div>
                <img
                  className="services-icon-image"
                  src={data.icon}
                  alt="services-icon-image"
                />

                <div className="services-sub-heading">{data.heading}</div>
                <div className="services-des">
                  <p>{data.des1}</p>
                  <p>{data.des2}</p>
                </div>
              </div>
              <CustomButton
                label="Learn More →"
                onClick={() => console.log("Button clicked!")}
                type="services-green-border"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpCenter;
