import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import { createJobReferralRequest, fetchDashboardSuccess, fetchDashboarsdFailure, getJobReferralById, getJobReferrals, updateJobSeekerFeedback } from "../../redux/actions/jobSeekerAction";
import { apiRequest } from "../../utils/apiRequest";


// Helper function to dispatch error messages
const handleApiError = (dispatch, error, errorMessage) => {
  console.error(errorMessage, error);
  dispatch(showToastMessage(errorMessage, "error"));
};

// Fetch job seeker list
export const fetchDashboard = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(`/api/job-referral/job-seeker/dashboard`, {}, false, dispatch);
      //pass response not response.data
      console.log(response);

      dispatch(fetchDashboardSuccess(response.data));
      dispatch(showToastMessage("JobSeeker fetched successfully!", "success"));
    } catch (error) {
      dispatch(fetchDashboarsdFailure(error));
      handleApiError(dispatch, error, "Failed to fetch JobSeeker.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getJobReferral = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await apiRequest(`/api/job-referral/job-seeker/${id}`, {}, false, dispatch);
      dispatch(getJobReferralById(response.data));
      dispatch(showToastMessage("Job referral retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update job seeker by ID
export const updateFeedback = (id, jobSeekerFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedJobSeeker = await apiRequest(
        `/api/job-referral/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(jobSeekerFeedback),
        },
        true, 
        dispatch
      );
      dispatch(updateJobSeekerFeedback(updatedJobSeeker));
      dispatch(showToastMessage("Jobseeker feedback updated successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to update jobseeker feedback.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchJobReferrals = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const jobReferralData = await apiRequest(`/api/job-referral/job-seeker`, {}, false, dispatch);
      console.log("Job Referral Data :", jobReferralData);

      dispatch(getJobReferrals(jobReferralData.data || []));
      dispatch(showToastMessage("Job Referrals retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve job referrals.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new job referral
export const addJobReferralRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/job-referral/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      console.log(response.data);

      dispatch(createJobReferralRequest(response.data));
      dispatch(showToastMessage("Job Referral added successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

