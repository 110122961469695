import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import {
  createUserSuccess, 
  createUserFailure,
  loginSuccess,
  loginFailure,
  resendOTPSuccess,
  resendOTPFailure,
  verifyOTPSuccess,
  verifyOTPFailure,
  createPasswordSuccess,
  createPasswordFailure,
  forgotPasswordSendOTPSuccess,
  forgotPasswordSendOTPFailure,
  forgotPasswordVerifyOTPSuccess,
  forgotPasswordVerifyOTPFailure,
  forgotPasswordCreatePasswordSuccess,
  forgotPasswordCreatePasswordFailure,
} from "../../redux/actions/authActions";

export const createUserAsync = (userData,setOtpVarification,setTimer) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await fetch("/api/user/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });
      if (!response.ok) throw new Error(`Failed to create user: ${response.message}`);

      const result = await response.json();
      console.log(result.data.userId);
      
      localStorage.setItem('user', result.data.userId);

      dispatch(createUserSuccess(result.data));
      dispatch(showToastMessage("Create user successfully!", "success"));
      setOtpVarification(true);
      setTimer(30)

    } catch (error) {
      console.error("Error creating user:", error);
      dispatch(createUserFailure(error.message));
      dispatch(showToastMessage("Failed to create user.", "error"));
    } finally {
      dispatch(stopLoading());
    }
  };
};


// Resend OTP
// export const resendOTPAsync = (userId) => {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     try {
//       const response = await fetch(`/api/resend_otp/${userId}`, { method: "POST" });
//       if (!response.ok) throw new Error(`Failed to resend OTP: ${response.statusText}`);
      
//       dispatch(resendOTPSuccess());
//       dispatch(showToastMessage("OTP resent successfully!", "success"));
//     } catch (error) {
//       console.error("Error resending OTP:", error);
//       dispatch(resendOTPFailure(error.message));
//       dispatch(showToastMessage("Failed to resend OTP.", "error"));
//     } finally {
//       dispatch(stopLoading());
//     }
//   };
// };

// Verify OTP
export const verifyOTPAsync = (otpData,setOtp,setOtpVarification,setIsCreatePassword,setTimer) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const user = localStorage.getItem('user');
console.log(user);
      const response = await fetch("/api/user/verify-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({userId:user,otp:otpData}),
      });
      if (!response.ok) throw new Error(`Failed to verify OTP: ${response.statusText}`);

      dispatch(verifyOTPSuccess());
      dispatch(showToastMessage("OTP verified successfully!", "success"));
      setOtpVarification(false);
    setIsCreatePassword(true);
    setTimer(0)
      setOtp(["", "", "", "", "", ""]);

    } catch (error) {
      console.error("Error verifying OTP:", error);
      dispatch(verifyOTPFailure(error.message));
      dispatch(showToastMessage("Failed to verify OTP.", "error"));
    } finally {
      dispatch(stopLoading());
    }
  };
};

// resend OTP
export const resendOTPAsync = (setTimer) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const user = localStorage.getItem('user');
console.log(user);
      const response = await fetch("/api/user/resend-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({userId:user}),
      });
      if (!response.ok) throw new Error(`Failed to verify OTP: ${response.statusText}`);

      dispatch(verifyOTPSuccess());
      dispatch(showToastMessage("OTP resend successfully!!", "success"));
      setTimer(30)

    } catch (error) {
      console.error("Error verifying OTP:", error);
      dispatch(verifyOTPFailure(error.message));
      dispatch(showToastMessage("Failed to resend OTP.", "error"));
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Create Password
export const createPasswordAsync = (password,confirmPassword,setOtpVarification,setIsCreatePassword,setIsLoginModal) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const user = localStorage.getItem('user');
      const response = await fetch("/api/user/create-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({password:password.password,confirmPassword:confirmPassword,userId:user}),
      });
      if (!response.ok) throw new Error(`Failed to create password: ${response.statusText}`);

      dispatch(createPasswordSuccess());
      dispatch(showToastMessage("Password created successfully!", "success"));
       setOtpVarification(false);
      setIsCreatePassword(false);
      setIsLoginModal(true);

    } catch (error) {
      console.error("Error creating password:", error);
      dispatch(createPasswordFailure(error.message));
      dispatch(showToastMessage("Failed to create password.", "error"));
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Login
export const loginAsync = (credentials,navigate) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await fetch("/user/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) throw new Error(`Login failed: ${response.statusText}`);

      const userData = await response.json();
      console.log(userData.data.token);
      if (userData.data.token) {
        // Store the token in localStorage (or sessionStorage if you prefer)
        localStorage.setItem('authToken', userData.data.token);
        localStorage.setItem('userId', userData.data.user.user);

        // Optionally, store user details if needed for later
        localStorage.setItem('user', JSON.stringify(userData.data));
        // navigate("/dashboard/event");

        // Dispatch the login success action with user data
        dispatch(loginSuccess(userData.data));
        
        // Navigate to the dashboard or another page

        // Show success toast message
        dispatch(showToastMessage("Logged in successfully!", "success"));
      } else {
        throw new Error('Token not received');
      }
    } catch (error) {
      console.error("Error during login:", error);
      dispatch(loginFailure(error.message));
      dispatch(showToastMessage("Failed to log in.", "error"));
    } finally {
      dispatch(stopLoading());
    }
  };
};

// // Forgot Password - Send OTP
// export const forgotPasswordSendOTPAsync = (email,navigate) => {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     try {
//       const response = await fetch("/api/send_otp_forgot_password", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify( email ),
//       });
//       const data = await response.json();
//         const { userId } = data;
//   console.log(data.data.userId);
  
//         // Store userId and OTP in localStorage
//         localStorage.setItem('userId', data.data.userId);
//       console.log(response,response.status);
//       if(response.status==200){

        
//         navigate("/forgotPassword/otp-verification");

//       }
//       if (!response.ok) throw new Error(`Failed to send OTP: ${response.statusText}`);

//       dispatch(forgotPasswordSendOTPSuccess());
//       dispatch(showToastMessage("OTP sent to your mobile!", "success"));
//     } catch (error) {
//       console.error("Error sending OTP:", error);
//       dispatch(forgotPasswordSendOTPFailure(error.message));
//       dispatch(showToastMessage("Failed to send OTP.", "error"));
//     } finally {
//       dispatch(stopLoading());
//     }
//   };
// };

// // Forgot Password - Verify OTP
// export const forgotPasswordVerifyOTPAsync = (otpData,navigate) => {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     try {
//       const userId = localStorage.getItem('userId');

//       const response = await fetch("/api/forgot_password/verify_otp", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(userId,otpData),
//       });
//       if (!response.ok) throw new Error(`Failed to verify OTP: ${response.statusText}`);

//       dispatch(forgotPasswordVerifyOTPSuccess());
//       dispatch(showToastMessage("OTP verified successfully!", "success"));
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       dispatch(forgotPasswordVerifyOTPFailure(error.message));
//       dispatch(showToastMessage("Failed to verify OTP.", "error"));
//     } finally {
//       dispatch(stopLoading());
//     }
//   };
// };

// // Forgot Password - Create New Password
// export const forgotPasswordCreatePasswordAsync = (passwordData) => {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     try {
//       const response = await fetch("/api/forgot_password/create_password", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(passwordData),
//       });
//       if (!response.ok) throw new Error(`Failed to create new password: ${response.statusText}`);

//       dispatch(forgotPasswordCreatePasswordSuccess());
//       dispatch(showToastMessage("Password reset successfully!", "success"));
//     } catch (error) {
//       console.error("Error creating new password:", error);
//       dispatch(forgotPasswordCreatePasswordFailure(error.message));
//       dispatch(showToastMessage("Failed to reset password.", "error"));
//     } finally {
//       dispatch(stopLoading());
//     }
//   };
// };
