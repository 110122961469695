import React from "react";
import "./DiscussionPost.css";
import webinarHost1 from "../../assets/images/webinarHost1.jpg";
import profile2 from "../../assets/images/profile2.jpg";
import upArrow from "../../assets/images/arrow-badge.svg";
import downArrow from "../../assets/images/arrow-down-badge.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const DiscussionPost = () => {
  const postAnswers = [
    {
      profileImg: profile2,
      name: "Posted by Sarah_MarketingPro",
      date: "27 April 2024",
      ans: "Congratulations on attending your first industry conference! Here are some strategies that have worked for me:",
    },
    {
      profileImg: profile2,
      name: "Posted by John_ITSpecialist",
      date: "27 April 2024",
      ans: "Networking can be daunting, but it’s incredibly rewarding. Here are a few tips:",
    },
    {
      profileImg: profile2,
      name: "Posted by Robert_SalesGuru",
      date: "27 April 2024",
      ans: "Networking is key to building professional relationships. Here are some strategies:",
    },
  ];

  return (
    <div className="main-discussions-container">
      <div className="main-discussion-post-container">
        <div className="learninghub-name-date-container">
          <div>Posted by Alex Carl</div>
          <div>27 April 2024</div>
        </div>
        <div className="discussion-post-container">
          <div className="discussion-profile-container">
            <div className="host-img-container">
              <img className="webinar-host-img" src={webinarHost1} alt="host" />
            </div>
            <div className="discussions-number-container">
              <img className="up-down-arrow" src={upArrow} />
              <div>24</div>
              <img className="up-down-arrow" src={downArrow} />
            </div>
          </div>

          <div className="discussion-post-description-container">
            <h5>
              What Are the Best Strategies for Networking at Industry Events?
            </h5>
            <p className="discussion-post-des">
              I'm attending my first major industry conference next month and I
              want to make the most of the networking opportunities. What are
              some effective strategies for connecting with professionals at
              these events? How can I make a lasting impression and build
              meaningful relationships? Any advice or personal experiences would
              be greatly appreciated!
            </p>
            <div className="comment-share-button-container">
              <CustomButton
                label="24 Upvotes"
                onClick={() => console.log("Button clicked!")}
                type="comment-share-button"
              />
              <CustomButton
                label="21 Comments"
                onClick={() => console.log("Button clicked!")}
                type="comment-share-button"
              />
              <CustomButton
                label="8 Shares"
                onClick={() => console.log("Button clicked!")}
                type="comment-share-button"
              />
            </div>
            <input
              className="post-comment-input"
              placeholder="Share your thoughts here..."
            />

            <div className="contact-form-button-container">
              <CustomButton
                label="Cancel"
                onClick={() => console.log("Button clicked!")}
                type="contact-form-button discussion-comment-button"
              />
              <CustomButton
                label="Comment"
                onClick={() => console.log("Button clicked!")}
                type="contact-form-button-purple discussion-comment-button"
              />
            </div>
          </div>
        </div>

        <h6>12 Answers</h6>
        {postAnswers.map((data) => (
          <div className="answers-container">
            <div className="learninghub-name-date-container">
              <div>{data.name}</div>
              <div>{data.date}</div>
            </div>

            <div className="discussion-post-container">
              <div className="discussion-profile-container">
                <div className="host-img-container">
                  <img
                    className="webinar-host-img"
                    src={data.profileImg}
                    alt="host"
                  />
                </div>
                <div className="discussions-number-container">
                  <img className="up-down-arrow" src={upArrow} />
                  <div>24</div>
                  <img className="up-down-arrow" src={downArrow} />
                </div>
              </div>

              <div className="discussion-post-description-container">
                <p className="discussion-ans">{data.ans}</p>

                <div className="comment-share-button-container">
                  <CustomButton
                    label="24 Upvotes"
                    onClick={() => console.log("Button clicked!")}
                    type="comment-share-button"
                  />
                  <CustomButton
                    label="21 Comments"
                    onClick={() => console.log("Button clicked!")}
                    type="comment-share-button"
                  />
                  <CustomButton
                    label="8 Shares"
                    onClick={() => console.log("Button clicked!")}
                    type="comment-share-button"
                  />
                  <CustomButton
                    label="Comment"
                    onClick={() => console.log("Button clicked!")}
                    type="contact-form-button-purple discussion-comment-button"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <CustomButton
        label="View More Answers"
        onClick={() => console.log("Button clicked!")}
        type="submit"
      />
    </div>
  );
};

export default DiscussionPost;
