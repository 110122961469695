import React from 'react'
import HelpCenter from '../containers/HelpCenter/HelpCenter'
import UpdatedWith from '../containers/OurServices/UpdatedWith'

const HelpCenterPage = () => {
  return (
    <>
    <div className="main-header-heading-condatiner">
      <h1>Help Center</h1>
      <p>
      Find answers, get in touch, and provide feedback to help us serve you better
      </p>
    </div>
    <div className="main-home-container">
        <HelpCenter/>
        <UpdatedWith/>
    </div>
  </>
  )
}

export default HelpCenterPage