import React from "react";
import "./NotificationSetting.css";

const NotificationSetting = () => {
  const notificationData = [
    {
      heading: "Job Referral Status Updates",
      des: " Get notified about changes to the status of your job referral requests, including approvals, rejections, and pending decisions.",
    },
    {
      heading: "New Job Referral Opportunities",
      des: "Receive alerts when new job referral opportunities that match your profile become available.  ",
    },
    {
      heading: "Career Advice Session Reminders ",
      des: "Stay informed with reminders for your upcoming career advice sessions, including time, date, and any required preparation. ",
    },
    {
      heading: "Resume Review Completion",
      des: "Get notified when your resume or cover letter review has been completed, with links to view the reviewed documents and feedback.",
    },
    {
      heading: "General Announcements",
      des: "Receive important announcements and updates from the platform, including new features, service changes, and more. ",
    },
  ];
  return (
    <>
      {notificationData.map((data) => (
        <div className="dashboard-notification-container">
          <div>
            <h6 className="dashboard-notification-heading">{data.heading}</h6>
            <p className="dashboard-notification-des">{data.des}</p>
          </div>
          <div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider"></span>
            </label>
          </div>
        </div>
      ))}
    </>
  );
};

export default NotificationSetting;
