import React from 'react';
import './CustomButton.css'

const CustomButton = ({ label, onClick, type, disabled, isOutlined }) => {
    const buttonClassName = `button ${type} ${isOutlined ? 'outlined' : ''}`;
  
    return (
      <button className={'button-common ' + buttonClassName} onClick={onClick} disabled={disabled}>
        {label}
      </button>
    );
};

export default CustomButton;
