import React, { useEffect, useState } from "react";
import "./ServiceManagemantTable.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import DashboardTable from "../../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../../components/CustomTable/CustomTable";

const CareerAdviceTable = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];

  const columns = [
    { key: "id", label: "ID" },
    { key: "UserName", label: "User Name" },
    { key: "Title", label: "Title"},
    { key: "CreatedDate", label: "Created Date" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const data = [
    {
      id: "JR002",
      UserName: "Janet Lee",
      Title: "Software Eng",
      CreatedDate: "12 Mar 2022",
      status: "Pending",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      Title: "Software Eng",
      CreatedDate: "12 Mar 2022",
      status: "Pending",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      Title: "Software Eng",
      CreatedDate: "12 Mar 2022",
      status: "Completed",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      Title: "Software Eng",
      CreatedDate: "12 Mar 2022",
      status: "Pending",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      Title: "Software Eng",
      CreatedDate: "12 Mar 2022",
      status: "Processing",
    },
  ];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
      // { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Learning Hub",
    des: "Oversee content and user progress to ensure a seamless and effective learning experience.",
  };
  const subNav = [
    { navHeading: "Job Referrals" },
    { navHeading: "Career Advice" },
    { navHeading: "Resume Review" },
  ];

  const [navClick, setNavClick] = useState("Job Referrals");
  const [heading, setHeading] = useState("Job Referrals");
  const [activeHeading, setActiveHeading] = useState("Job Referrals");

  useEffect(() => {
    if (heading == "Account Settings") {
      console.log("Job Referrals");
      setNavClick("Job Referrals");
    } else {
      setNavClick("notification");
    }
    console.log("abc", navClick, navClick == "account");
  }, [heading]);

  const handleClick = (heading) => {
    setActiveHeading(heading);
    setHeading(heading);
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="setting-page-container">
        <div className="dashboard-subnav-button-container">
          {subNav.map((data) => (
            <div
              key={data.navHeading}
              onClick={() => handleClick(data.navHeading)}
              className={`subnav-button-item ${
                activeHeading === data.navHeading ? "active" : ""
              }`}
            >
              <div>{data.navHeading}</div>
            </div>
          ))}
        </div>

        <div className="sub-setting-page-container blog-post-table-container">
        <CustomTable
            columns={columns}
            data={data}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default CareerAdviceTable;
