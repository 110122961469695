import React, { useState } from "react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import "./AccVerification.css";
import questionCircle from "../../../assets/images/UserLayout/Icons/question-circle.svg";
import creditCard from "../../../assets/images/UserLayout/Icons/credit_card.svg";
import closeIcon from "../../../assets/images/UserLayout/Icons/close.svg";
import { useNavigate } from "react-router-dom";

const AccVerification = () => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const navigate = useNavigate();

  const handleResumeUpload = (file) => {
    setResume(file);
  };

  const handleCoverLetterUpload = (file) => {
    setCoverLetter(file);
  };

  const handleSubmit = () => {
    navigate("/referrer_varification/referrer_info");
  };
  return (
    <div className="user-job-referral-form-request-container">
      <h4>Account Verification</h4>
      <h5 className="referral-form-sub-heading">Provide Your Details</h5>
      <p>Please fill in the information below to start the referral process.</p>

      <form className="user-job-referral-form-request">
        <div>
          <h5>User Information Form</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  E-Mail Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  placeholder="Enter your email address"
                  required
                />
              </div>
            </div>
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Phone Number<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="phoneNo"
                  className="form-input"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Phone Number (Office)<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="officePhoneNo"
                  className="form-input"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5>Company Details</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyName"
                  className="form-input"
                  placeholder="Enter your company name"
                  required
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyAddress"
                  className="form-input"
                  placeholder="Enter your company address"
                  required
                />
              </div>
            </div>
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Website<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="website"
                  className="form-input"
                  placeholder="Enter your company website"
                  required
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Contact Number<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyContactNumber"
                  className="form-input"
                  placeholder="Enter your company contact number"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5>Current Position</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Referrer's Current Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="position"
                  className="form-input"
                  placeholder="Enter your current position"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="upload-section">
            <h5>Identity Verification</h5>
            <FileUpload
              fileHeading="Passport"
              uploadDes="Drag and Drop or Choose your Passport to upload"
            />
          </div>

          <div className="upload-section">
            <FileUpload
              fileHeading="User Image Upload"
              uploadDes="Drag and Drop or Choose your User Image to upload or Take Photo"
            />
          </div>
        </div>
        <div className="user-request-form-actions">
          <button className="button-common user-dashboard-table-button pay-cancel-button">
            Cancel
          </button>
          <button
            className="button-common user-dashboard-table-button"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccVerification;
