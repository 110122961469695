import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';

import authReducer from './reducers/authReducer'; // Import your authReducer and other reducers here
import toastReducer from './reducers/toastReducer';
import jobSeekerReducer from './reducers/jobSeekerReducer';


// Combine multiple reducers if you have more than one
const rootReducer = combineReducers({
  auth: authReducer,
  toast:toastReducer,
  jobSeeker:jobSeekerReducer
  
});

// Create the Redux store with the combined reducers and apply middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
