import React from 'react'
import people1 from "../../../../assets/images/people1.jpg";
import people2 from "../../../../assets/images/people2.jpg";
import people3 from "../../../../assets/images/people3.jpg";

const ChatManagerSideBar = () => {
    const jobListings = [
        {
          id: 1,
          title: "Software Engineer",
          company: "ABC (PVT) LTD - Software...",
          time: "03:40am",
          badge: 15,
          img1: people1,
          img2: people2,
        },
        {
          id: 1,
          title: "Software Engineer",
          company: "ABC (PVT) LTD - Software...",
          time: "03:40am",
          badge: 15,
          img1: people1,
          img2: people2,
        },
        {
          id: 1,
          title: "Software Engineer",
          company: "ABC (PVT) LTD - Software...",
          time: "03:40am",
          badge: 15,
          img1: people1,
          img2: people2,
        },
        {
          id: 1,
          title: "Software Engineer",
          company: "ABC (PVT) LTD - Software...",
          time: "03:40am",
          badge: 15,
          img1: people1,
          img2: people2,
        },
        {
          id: 1,
          title: "Software Engineer",
          company: "ABC (PVT) LTD - Software...",
          time: "Yesterday",
          badge: 15,
          img1: people1,
          img2: people2,
        },
      ];
  return (
    <div className="jri-jobs-list">
    {jobListings.map((job, index) => (
      <div key={index} className="jri-job-item">
        <div className="jri-job-avatars">
          <div className="jri-avatar-group">
            <img
              src={job.img1}
              alt="Avatar 1"
              className="jri-avatar"
            />
            <img
              src={job.img2}
              alt="Avatar 2"
              className="jri-avatar jri-avatar-overlap"
            />
          </div>
        </div>
        <div className="jri-job-info">
          <h3 className="jri-job-title">{job.title}</h3>
          <p className="jri-job-company">{job.company}</p>
        </div>
        <div className="jri-job-meta">
          <span className="jri-job-time">{job.time}</span>
          {job.badge && (
            <span className="jri-badge">{job.badge}</span>
          )}
        </div>
      </div>
    ))}
  </div>


  )
}

export default ChatManagerSideBar