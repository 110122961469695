import React from "react";
import "./Journey.css";
import journeyTextImgLeft from "../../assets/images/journeyTextImgLeft.svg";
import journeyTextImgRight from "../../assets/images/journeyTextImgRight.svg";
import journeyImg from "../../assets/images/journeyImg.png";

const Journey = () => {
  return (
    <div className="main-journey-container">
      <div className="about-us-container">
        <div className="about-us-heading">
          The Konect <span className="heading-different-color"> Journey</span>
        </div>
        <p>From Vision to Reality in Career Connections</p>
        <div className="des-paragraph">
          Konect was founded with the belief that everyone deserves a fair
          chance at their dream job. Our journey began when we recognized the
          challenges job seekers face without internal connections. Job seekers
          often struggle to access opportunities due to a lack of personal
          networks, and we saw an opportunity to bridge this gap.
        </div>
        <div className="des-paragraph">
          Starting from a small team with a big vision, we focused on creating a
          platform that leverages the power of internal referrals. We aimed to
          democratize access to job opportunities by connecting talented
          individuals with employees who can provide valuable referrals. This
          approach not only helps job seekers but also benefits companies by
          bringing in qualified candidates who might otherwise be overlooked.
        </div>
        <div className="success-services-image-container">
          <img src={journeyTextImgLeft} />
          <img src={journeyTextImgRight} />
        </div>
      </div>
      <div>
        <img className="journey-img" src={journeyImg} />
      </div>
    </div>
  );
};

export default Journey;
