import DashboardIcon from "../../../assets/images/adminLayout/sidebar/DashboardIcon.svg";
import DocumentIcon from "../../../assets/images/adminLayout/sidebar/DocumentIcon.svg";
import FileIcon from "../../../assets/images/adminLayout/sidebar/FileIcon.svg";
import FolderIcon from "../../../assets/images/adminLayout/sidebar/FolderIcon.svg";
import MessageIcon from "../../../assets/images/adminLayout/sidebar/MessageIcon.svg";
import SettingIcon from "../../../assets/images/adminLayout/sidebar/SettingIcon.svg";
import UserIcon from "../../../assets/images/adminLayout/sidebar/UserIcon.svg";
const topbar = [
  {
    title: "Dashboard",
    path: "/referrer_dashboard/",
  },
  
  {
    title: "Profile ",
    path: "/referrer_varification/",
  },

  {
    title: "Messenger",
    path: "/referrer_dashboard/messenger",
  },
  {
    title: "Settings",
    path: "/referrer_dashboard/settings/referrer",
  },
];

export default topbar;
