import React from "react";
import './Header.css'
import headerImage from "../../../assets/images/landing-content-img-right.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import accountCircle from "../../../assets/images/ReferrerLayout/Icons/account_circle.svg";
import settings from "../../../assets/images/ReferrerLayout/Icons/settings.svg";
import { useNavigate } from "react-router-dom";


const Header = () => {
  const navigate = useNavigate();
  const handleSettingClick=()=>{
    navigate("/referrer_varification/settings")
  }

  const handleProfileClick=()=>{
    navigate("/referrer_varification/")
  }



    return (
      <div
        className="main-landing-referrer-header-container"
      >
         <div className="landing-referrer-header-container">
        <div className="landing-header-detail-container">
          <div>
            <h1>
              <span className="heading-different-color">Welcome</span>{" "}, Amelia Clarke!{" "}
            </h1>
          </div>
          <div className="landing-header-des">
          Empowering you to connect top talent with the best opportunities. Manage your referrals, track application statuses, and ensure successful placements with ease.
          </div>
          <div className="landing-referral-header-button-container">
            {/* <CustomButton
              label="Get Started →"
              onClick={() => console.log("Button clicked!")}
              type="submit"
            />
            <CustomButton
              label="Learn More"
              onClick={() => console.log("Button clicked!")}
              type="green-border"
            /> */}
            <button className="button-common referral-header-button" onClick={handleProfileClick}>
                <img className="referral-header-button-icon" src={accountCircle}/>
                profile
            </button>
            <button className="button-common referral-header-button header-button-colour" onClick={handleSettingClick}>
                <img className="referral-header-button-icon" src={settings}/>
                Settings
            </button>
          </div>
        </div>
        <img
          className="landing-referral-header-image"
          src={headerImage}
          alt="landing-header-image"
        />
        {/* </div> */}
      </div>
      </div>
    );
  };

export default Header;
