import React from "react";
import "./ChatContainer.css";
import people1 from "../../../../assets/images/people1.jpg";
import people2 from "../../../../assets/images/people2.jpg";
import people3 from "../../../../assets/images/people3.jpg";

const ChatContainer = () => {
  const dummyMessages = [
    {
      id: 1,
      sender: "Paityn Siphron",
      text: "Hey Nice to meet you",
      time: "12:30am",
      avatar: people1,
      isUser: true,
    },
    {
      id: 2,
      sender: "John Doe",
      text: "Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.u",
      time: "12:30am",
      avatar: people2,
      isUser: false,
    },
    {
      id: 3,
      sender: "Paityn Siphron",
      text: "Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt ut",
      time: "12:30am",
      avatar: people1,
      isUser: true,
    },
    {
      id: 4,
      sender: "John Doe",
      text: "Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.u",
      time: "12:30am",
      avatar: people2,
      isUser: false,
    },
    
  ];
  return (
    <div className="chat-app-container">
      <div className="chat-app-messages">
        {dummyMessages.map((message) => (
          <div
            key={message.id}
            className={`chat-app-message ${
              message.isUser ? "sent" : "received"
            }`}
          >
            {message.isUser && (
                <div className="chat-avatar-container">
              <img
                src={message.avatar}
                alt="Avatar"
                className="jri-avatar"
              />
              </div>
            )}
            <div className="chat-app-message-content">
              <div>{message.text}</div>
              <span className="chat-app-message-time">{message.time}</span>
            </div>
            {!message.isUser && (
              <div className="chat-avatar-container">
                <img
                  src={message.avatar}
                  alt="Avatar"
                  className="jri-avatar"
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="chat-app-input-container">
        <div className="chat-app-input-wrapper">
          <input
            type="text"
            placeholder="Type a Message...."
            className="chat-app-text-input"
          />
          <button className="chat-app-attachment-btn">
            <span className="chat-app-icon">📎</span>
          </button>
          <button className="chat-app-send-btn">
            <span className="chat-app-icon">➤</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
