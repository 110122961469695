import React, { useEffect, useState } from "react";
import TitlePath from "../../../components/TitlePath/TitlePath";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import DashboardSubNavbar from "../../../components/DashboardSubNavbar/DashboardSubNavbar";
import "./UserSettings.css";
import AccountSetting from "../../../containers/DashboardLayout/AccountSetting/AccountSetting";
import NotificationSetting from "../../../containers/DashboardLayout/NotificationSetting/NotificationSetting";
import RefereeVerification from "../../ReferrerLayoutPage/RefereeVerification/RefereeVerification";

export default function UserSettings() {
  const navigate = useNavigate();
  const params = useParams();
  console.log(params.id);

  const [isReferrer, setIsReferrer] = useState();
  const [isSettingPage, setIsSettingPage] = useState(false);

  const handleBack = () => {
    // navigate(`/user_dashboard/settings`);
  };
  const titlePath = {
    exactPath: `Account Setting `,
    paths: [
      { label: "Setting > ", onClick: handleBack },
      // { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Setting",
    des: "Manage your Learning Hub's articles, webinars, and quizzes",
  };

  const subNav = [
    { navHeading: "Account Settings" },
    { navHeading: "Notification Settings" },
  ];

  if (isReferrer == "referrer") {
    subNav.push({ navHeading: "Registration & Verification" });
    // setIsSettingPage(true)
  }

  const [navClick, setNavClick] = useState("account");
  const [heading, setHeading] = useState("Account Settings");
  const [activeHeading, setActiveHeading] = useState("Account Settings");

  useEffect(() => {
    if (heading === "Account Settings") {
        console.log("account");
        setNavClick("account");
    } else if (heading === "Notification Settings") {
        setNavClick("notification");
    } else {
        setNavClick("register");
    }

    console.log("abc", navClick, navClick === "account");
}, [heading]);


  console.log("abc", navClick, navClick == "account");

  useEffect(() => {
    setIsReferrer(params.id);
    console.log("rrrrr", params.id);
  }, [params.id]);

  return (
    <div className="main-dashboard-container main-user-setting-dashboard">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="setting-page-container">
        <DashboardSubNavbar
          subNav={subNav}
          setNavClick={setNavClick}
          setHeading={setHeading}
          activeHeading={activeHeading}
          setActiveHeading={setActiveHeading}
        />
        <hr className="user-setting-hr" />
        <div className="sub-setting-page-container user-setting-page">
          {navClick === "account" ? (
            <AccountSetting privilege="user" />
          ) : navClick === "notification" ? (
            <NotificationSetting />
          ) : (
            <RefereeVerification  isReferrer={'referrer'}/>
          )}
        </div>
      </div>
    </div>
  );
}
