import React, { useEffect, useState } from "react";
import "./JobReferrals.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import addCircleIcon from "../../../assets/images/UserLayout/Icons/add_circle_icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobReferrals } from "../../../services/jobSeeker/jobSeekerService";

export default function UserJobReferrals() {
  const columns = [
    { key: "id", label: "ID" },
    { key: "UserName", label: "User Name" },
    { key: "JobTitle", label: "Job Title" },
    { key: "Company", label: "Company" },
    { key: "SubmissionDate", label: "Submission Date" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const [activeTab, setActiveTab] = useState("All");


  // const data = [
  //   {
  //     id: "JR002",
  //     UserName: "Janet Lee",
  //     JobTitle: "Software Eng",
  //     Company: "VisionDigital",
  //     SubmissionDate: "12 Mar 2022",
  //     status: "Pending",
  //   },
  //   {
  //     id: "JR002",
  //     UserName: "Janet Lee",
  //     JobTitle: "Software Eng",
  //     Company: "VisionDigital",
  //     SubmissionDate: "12 Mar 2022",
  //     status: "Approved",
  //   },
  //   {
  //     id: "JR002",
  //     UserName: "Janet Lee",
  //     JobTitle: "Software Eng",
  //     Company: "VisionDigital",
  //     SubmissionDate: "12 Mar 2022",
  //     status: "Rejected",
  //   },
  //   {
  //     id: "JR002",
  //     UserName: "Janet Lee",
  //     JobTitle: "Software Eng",
  //     Company: "VisionDigital",
  //     SubmissionDate: "12 Mar 2022",
  //     status: "Processing",
  //   },
  //   {
  //     id: "JR002",
  //     UserName: "Janet Lee",
  //     JobTitle: "Software Eng",
  //     Company: "VisionDigital",
  //     SubmissionDate: "12 Mar 2022",
  //     status: "Pending",
  //   },
  // ];

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];

  const dispatch = useDispatch();

  const jobReferrals = useSelector((state) => state.jobSeeker.jobSeeker); 

  useEffect(() => {
    dispatch(fetchJobReferrals()); 
  }, [dispatch]);

  console.log("Job Referrals in Component:", jobReferrals);

  

  // Map job referrals to table data format
  const mappedData = jobReferrals?.map((item) => ({
    id: item.index,
    UserName: item.jobSeekerDetails.name,
    JobTitle: item.industryDetails.desiredPosition,
    Company: item.industryDetails.desiredCompany,
    SubmissionDate: new Date(item.submissionDate).toLocaleDateString(),
    status: item.currentStatus,
  }));


  const navigate = useNavigate();
  const handleRequestClick=()=>{
    navigate("/user_dashboard/job_referrals_newrequest")
  }
  return (
    <div className="main-user-dashboard-table-container">
      <div className="user-dashboard-table-heading-container">
        <div>
          <h4 className="user-dashboard-table-main-heading">Job Referrals</h4>
          <p className="user-dashboard-table-des">
            Get connected to job opportunities through our referral program.
            Submit a request and let us help you find your next job.
          </p>
        </div>
        <div>
          <button className="button-common user-dashboard-table-button" onClick={handleRequestClick}>
            <img
              src={addCircleIcon}
              className="user-dashboard-table-button-icon"
            />
            Request a Referral
          </button>
        </div>
      </div>
      <div className="user-dashboard-table-container">
        <CustomTable
          columns={columns}
          data={mappedData}
          tabs={tabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
}
